import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleBody } from "../Components/ItemTitleBody";
import { ItemTitleLefthindleg } from "../Components/ItemTitleLefthindleg";
import { ItemTitleRighthindleg } from "../Components/ItemTitleRighthindleg";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import Radobaanimage from "../images/Radobaan.png";
interface RadobaanData {
  data: {
    Radobaan: string[][];
  };
}

const Radobaan: React.FC = () => {
  const [data, setData] = useState<RadobaanData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/radobaandata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: RadobaanData) => {
      // 文字列に変換する
      const stringData: RadobaanData = {
        data: {
          Radobaan: responseData.data.Radobaan.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level2") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RadobaanHeadlv2 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[16][15])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="RadobaanBodylv2 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[30][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[30][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[30][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[30][15])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="RadobaanLeftHindleglv2 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[44][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[44][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[44][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[44][15])}</span>
          </div>
        </div>

        < ItemTitleRighthindleg />
        <div className="RadobaanRightHindleglv2 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[58][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[58][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[58][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[58][15])}</span>
          </div>
        </div>

        < ItemTitleTail />
        <div className="RadobaanTaillv2 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[72][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[72][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[72][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[72][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level3") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RadobaanHeadlv3 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[17][15])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="RadobaanBodylv3 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[31][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[31][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[31][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[31][15])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="RadobaanLeftHindleglv3 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[45][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[45][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[45][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[45][15])}</span>
          </div>
        </div>

        < ItemTitleRighthindleg />
        <div className="RadobaanRightHindleglv3 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[59][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[59][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[59][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[59][15])}</span>
          </div>
        </div>

        < ItemTitleTail />
        <div className="RadobaanTaillv3 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[73][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[73][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[73][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[73][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RadobaanHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[18][18])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="RadobaanBodylv4 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[32][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[32][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[32][18])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="RadobaanLeftHindleglv4 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[46][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="RadobaanRightHindleglv4 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[60][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[60][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[60][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[60][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[60][18])}</span>
          </div>
        </div>

        < ItemTitleTail />
        <div className="RadobaanTaillv4 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[74][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[74][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[74][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[74][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[74][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RadobaanHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[19][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[19][18])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="RadobaanBodylv5 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[33][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[33][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[33][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[33][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[33][18])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="RadobaanLeftHindleglv5 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[47][18])}</span>
          </div>
        </div>

        < ItemTitleRighthindleg />
        <div className="RadobaanRightHindleglv5 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[61][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[61][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[61][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[61][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[61][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RadobaanTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[75][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[75][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[75][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[75][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[75][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RadobaanHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[20][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[20][21])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="RadobaanBodylv6 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[34][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[34][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[34][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[34][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[34][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[34][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="RadobaanLeftHindleglv6 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[48][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="RadobaanRightHindleglv6 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[62][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[62][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[62][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[62][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[62][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[62][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RadobaanTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[76][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[76][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[76][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[76][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[76][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[76][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RadobaanHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[21][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[21][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[21][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[21][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[21][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[21][21])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="RadobaanBodylv7 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[35][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[35][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="RadobaanLeftHindleglv7 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[49][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="RadobaanRightHindleglv7 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[63][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[63][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[63][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[63][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[63][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[63][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RadobaanTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[77][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[77][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[77][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[77][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[77][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[77][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RadobaanHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[22][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[22][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[22][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[22][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[22][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[22][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[22][24])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="RadobaanBodylv8 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[36][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[36][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="RadobaanLeftHindleglv8 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[50][24])}</span>
          </div>
        </div>

        < ItemTitleRighthindleg />
        <div className="RadobaanRightHindleglv8 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[64][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[64][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[64][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[64][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[64][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[64][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[64][24])}</span>
          </div>
        </div>

        < ItemTitleTail />
        <div className="RadobaanTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[78][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[78][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[78][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[78][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[78][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[78][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[78][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RadobaanHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[23][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[23][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[23][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[23][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[23][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[23][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[23][24])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="RadobaanBodylv9 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[37][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[37][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="RadobaanLeftHindleglv9 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[51][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="RadobaanRightHindleglv9 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[65][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[65][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[65][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[65][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[65][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[65][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[65][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RadobaanTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[79][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[79][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[79][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[79][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[79][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[79][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[79][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RadobaanHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[24][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[24][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[24][24])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="RadobaanBodylv10 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="RadobaanLeftHindleglv10 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[52][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[52][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[52][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[52][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[52][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[52][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[52][24])}</span>
          </div>
        </div>

        < ItemTitleRighthindleg />
        <div className="RadobaanRightHindleglv10 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[66][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[66][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[66][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[66][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[66][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[66][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[66][24])}</span>
          </div>
        </div>

        < ItemTitleTail />
        <div className="RadobaanTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>骨鎚竜の鱗</a>
            <span>{String(data?.data.Radobaan[80][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の甲殻</a>
            <span>{String(data?.data.Radobaan[80][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の尻尾</a>
            <span>{String(data?.data.Radobaan[80][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の黒油殻</a>
            <span>{String(data?.data.Radobaan[80][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の良質な鱗</a>
            <span>{String(data?.data.Radobaan[80][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>骨鎚竜の骨髄</a>
            <span>{String(data?.data.Radobaan[80][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Radobaan[80][24])}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level2">★2</option>
            <option value="level3">★3</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Radobaanimage} alt="" className="RadobaanImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Radobaan;
