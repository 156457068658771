import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleHead2 } from "../Components/ItemTitleHead2";

import Tzitziyakuimage from "../images/Tzitzi-Ya-Ku.png";
interface TzitziyakuData {
  data: {
    Tzitziyaku: string[][];
  };
}

const Tzitziyaku: React.FC = () => {
  const [data, setData] = useState<TzitziyakuData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/tzitziyakudata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: TzitziyakuData) => {
      // 文字列に変換する
      const stringData: TzitziyakuData = {
        data: {
          Tzitziyaku: responseData.data.Tzitziyaku.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level2") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TzitziyakuHeadlv2 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[16][15])}</span>
          </div>
        </div>
        
        < ItemTitleHead2 />
        <div className="TzitziyakuForelegslv2 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[30][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[30][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[30][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[30][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level3") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TzitziyakuHeadlv3 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[17][15])}</span>
          </div>
        </div>
        
        < ItemTitleHead2 />
        <div className="TzitziyakuForelegslv3 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[31][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[31][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[31][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[31][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TzitziyakuHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[18][18])}</span>
          </div>
        </div>
        
        < ItemTitleHead2 />
        <div className="TzitziyakuForelegslv4 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[32][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[32][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[32][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TzitziyakuHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[19][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[19][18])}</span>
          </div>
        </div>
        
        < ItemTitleHead2 />
        <div className="TzitziyakuForelegslv5 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[33][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[33][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[33][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[33][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[33][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TzitziyakuHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[20][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[20][21])}</span>
          </div>
        </div>
        
        < ItemTitleHead2 />
        <div className="TzitziyakuForelegslv6 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[34][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[34][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[34][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[34][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[34][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[34][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TzitziyakuHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[21][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[21][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[21][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[21][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[21][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[21][21])}</span>
          </div>
        </div>
        
        < ItemTitleHead2 />
        <div className="TzitziyakuForelegslv7 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[35][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[35][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TzitziyakuHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[22][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[22][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[22][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[22][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[22][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[22][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tzitziyaku[22][24])}</span>
          </div>
        </div>
        
        < ItemTitleHead2 />
        <div className="TzitziyakuForelegslv8 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[36][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tzitziyaku[36][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TzitziyakuHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[23][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[23][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[23][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[23][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[23][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[23][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tzitziyaku[23][24])}</span>
          </div>
        </div>
        
        < ItemTitleHead2 />
        <div className="TzitziyakuForelegslv9 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[37][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tzitziyaku[37][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TzitziyakuHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[24][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[24][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tzitziyaku[24][24])}</span>
          </div>
        </div>
        
        < ItemTitleHead2 />
        <div className="TzitziyakuForelegslv10 dropRate__box">
          <div className="dropRate__item">
            <a>眩鳥の鱗</a>
            <span>{String(data?.data.Tzitziyaku[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の皮</a>
            <span>{String(data?.data.Tzitziyaku[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の爪</a>
            <span>{String(data?.data.Tzitziyaku[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の発光膜</a>
            <span>{String(data?.data.Tzitziyaku[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な皮</a>
            <span>{String(data?.data.Tzitziyaku[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>眩鳥の良質な発光膜</a>
            <span>{String(data?.data.Tzitziyaku[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tzitziyaku[38][24])}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level2">★2</option>
            <option value="level3">★3</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Tzitziyakuimage} alt="" className="TzitziyakuImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Tzitziyaku;
