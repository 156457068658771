import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleForelegs } from "../Components/ItemTitleForelegs";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import { ItemTitleLefthorn } from "../Components/ItemTitleLefthorn";
import { ItemTitleRighthorn } from "../Components/ItemTitleRighthorn";
import Zinogreimage from "../images/Zinogre.png";
interface ZinogreData {
  data: {
    Zinogre: string[][];
  };
}

const Zinogre: React.FC = () => {
  const [data, setData] = useState<ZinogreData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/zinogredata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: ZinogreData) => {
      // 文字列に変換する
      const stringData: ZinogreData = {
        data: {
          Zinogre: responseData.data.Zinogre.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleBack />
        <div className="ZinogreBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[13][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[13][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[13][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[13][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[13][18])}</span>
          </div>
        </div>
        
        < ItemTitleLefthorn />
        <div className="ZinogreLeftHornlv5 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[24][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="ZinogreRightHornlv5 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[35][18])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="ZinogreForelegslv5 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[46][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="ZinogreTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[57][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[57][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[57][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[57][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[57][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleBack />
        <div className="ZinogreBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[14][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[14][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthorn />
        <div className="ZinogreLeftHornlv6 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[25][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="ZinogreRightHornlv6 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[36][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="ZinogreForelegslv6 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[47][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[47][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="ZinogreTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[58][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[58][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[58][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[58][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[58][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[58][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleBack />
        <div className="ZinogreBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[15][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[15][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthorn />
        <div className="ZinogreLeftHornlv7 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[26][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="ZinogreRightHornlv7 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[37][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="ZinogreForelegslv7 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[48][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="ZinogreTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[59][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[59][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[59][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[59][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[59][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[59][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleBack />
        <div className="ZinogreBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[16][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[16][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthorn />
        <div className="ZinogreLeftHornlv8 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[27][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[27][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[27][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="ZinogreRightHornlv8 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="ZinogreForelegslv8 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[49][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[49][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="ZinogreTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[60][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[60][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[60][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[60][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[60][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[60][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[60][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleBack />
        <div className="ZinogreBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[17][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[17][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthorn />
        <div className="ZinogreLeftHornlv9 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[28][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[28][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="ZinogreRightHornlv9 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[39][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="ZinogreForelegslv9 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[50][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="ZinogreTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[61][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[61][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[61][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[61][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[61][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[61][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[61][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleBack />
        <div className="ZinogreBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthorn />
        <div className="ZinogreLeftHornlv10 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[29][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[29][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="ZinogreRightHornlv10 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[40][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="ZinogreForelgslv10 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[51][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="ZinogreTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>雷狼竜の爪</a>
            <span>{String(data?.data.Zinogre[62][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の甲殻</a>
            <span>{String(data?.data.Zinogre[62][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の尻尾</a>
            <span>{String(data?.data.Zinogre[62][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の帯電毛</a>
            <span>{String(data?.data.Zinogre[62][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の良質な爪</a>
            <span>{String(data?.data.Zinogre[62][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雷狼竜の角</a>
            <span>{String(data?.data.Zinogre[62][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Zinogre[62][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Zinogreimage} alt="" className="ZinogreImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Zinogre;
