import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import RathalosTable from "../MonsterTableComponents/RathalosTable";
import Rathalos from "../MonsterbreakComponents/Rathalos";

const Rathalospage = () => {
    return (
        <div>
            <Header />
            <RathalosTable />
            <Rathalos />
            <Footer />
        </div>
    );
};

export default Rathalospage