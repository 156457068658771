import React from 'react'
import "../assets/css/reset.css";
import "../assets/css/decolation.css";

export const DecolationShort = () => {
  return (
    <div className='deco short'>
        <div className='deco__inner short'>
            <span className='deco__square'></span>
            <span className='deco__border'></span>
            <span className='deco__square__last'></span>
        </div>
    </div>
  )
}
