import React from 'react'
import { Footer } from "./Footer";
import { Header } from "./Header";
import  Privacy  from "./Privacy";


const Termspage = () => {
  return (
    <div>            
      <Header />
      <Privacy />  
      <Footer />
    </div>
  )
}

export default Termspage