import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import BlackDiablosTable from "../MonsterTableComponents/BlackDiablosTable";
import BlackDiablos from "../MonsterbreakComponents/BlackDiablos";

const BlackDiablospage = () => {
    return (
        <div>
            <Header />
            <BlackDiablosTable />
            <BlackDiablos />
            <Footer />
        </div>
    );
};

export default BlackDiablospage