import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleLefthindleg } from "../Components/ItemTitleLefthindleg";
import { ItemTitleRighthindleg } from "../Components/ItemTitleRighthindleg";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import Anjanathimage from "../images/Anjanath.png";
interface AnjanathData {
  data: {
    Anjanath: string[][];
  };
}

const Anjanath: React.FC = () => {
  const [data, setData] = useState<AnjanathData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/anjanathdata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: AnjanathData) => {
      // 文字列に変換する
      const stringData: AnjanathData = {
        data: {
          Anjanath: responseData.data.Anjanath.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AnjanathHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[14][18])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="AnjanathLeftHindleglv4 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[26][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="AnjanathRightHindleglv4 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[38][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AnjanathTaillv4 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[50][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AnjanathHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[15][18])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="AnjanathLeftHindleglv5 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[27][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="AnjanathRightHindleglv5 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[39][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AnjanathTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[51][18])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AnjanathHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[16][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="AnjanathLeftHindleglv6 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[28][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="AnjanathRightHindleglv6 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[40][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AnjanathTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[52][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[52][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[52][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[52][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[52][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[52][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AnjanathHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[17][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="AnjanathLeftHindleglv7 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[29][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="AnjanathRightHindleglv7 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[41][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[41][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[41][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[41][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[41][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[41][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AnjanathTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[53][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[53][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[53][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[53][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[53][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[53][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AnjanathHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="AnjanathLeftHindleglv8 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[30][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[30][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[30][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[30][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[30][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[30][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[30][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="AnjanathRightHindleglv8 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[42][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[42][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[42][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[42][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[42][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[42][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[42][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AnjanathTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[54][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[54][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[54][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[54][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[54][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[54][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[54][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AnjanathHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[19][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[19][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[19][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[19][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="AnjanathLeftHindleglv9 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[31][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[31][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[31][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[31][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[31][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[31][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[31][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="AnjanathRightHindleglv9 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[43][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[43][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[43][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[43][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[43][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[43][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[43][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AnjanathTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[55][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[55][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[55][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[55][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[55][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[55][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[55][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AnjanathHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[20][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[20][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[20][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="AnjanathLeftHindleglv10 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[32][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[32][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[32][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[32][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[32][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="AnjanathRightHindleglv10 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[44][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[44][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[44][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[44][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[44][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[44][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[44][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AnjanathTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>蛮顎竜の鱗</a>
            <span>{String(data?.data.Anjanath[56][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の牙</a>
            <span>{String(data?.data.Anjanath[56][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の尻尾</a>
            <span>{String(data?.data.Anjanath[56][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の鼻骨</a>
            <span>{String(data?.data.Anjanath[56][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の良質な鱗</a>
            <span>{String(data?.data.Anjanath[56][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蛮顎竜の逆鱗</a>
            <span>{String(data?.data.Anjanath[56][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Anjanath[56][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Anjanathimage} alt="" className="AnjanathImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Anjanath;
