import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import KuluyakuTable from "../MonsterTableComponents/KuluyakuTable";
import KuluYaku from "../MonsterbreakComponents/KuluYaku";

const KululuYakupage = () => {
    return (
        <div>
            <Header />
            <KuluyakuTable />
            <KuluYaku />
            <Footer />
        </div>
    );
};

export default KululuYakupage