import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleBody } from "../Components/ItemTitleBody";
import Deviljhoimage from "../images/DevilJho.png";
interface DeviljhoData {
  data: {
    Deviljho: string[][];
  };
}

const Deviljho: React.FC = () => {
  const [data, setData] = useState<DeviljhoData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/Deviljho", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: DeviljhoData) => {
      // 文字列に変換する
      const stringData: DeviljhoData = {
        data: {
          Deviljho: responseData.data.Deviljho.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DeviljhoHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[13][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[13][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[13][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[13][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[13][18])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="DeviljhoLeftWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[24][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DeviljhoRightWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[35][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DeviljhoHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[14][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[14][21])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="DeviljhoLeftWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[25][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DeviljhoRightWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[36][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DeviljhoHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[15][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[15][21])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="DeviljhoLeftWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[26][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DeviljhoRightWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[37][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DeviljhoHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[16][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の唾液</a>
            <span>{String(data?.data.Deviljho[16][24])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="DeviljhoLeftWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[27][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[27][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の唾液</a>
            <span>{String(data?.data.Deviljho[27][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DeviljhoRightWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の唾液</a>
            <span>{String(data?.data.Deviljho[38][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DeviljhoHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[17][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の唾液</a>
            <span>{String(data?.data.Deviljho[17][24])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="DeviljhoLeftWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[28][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の唾液</a>
            <span>{String(data?.data.Deviljho[28][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DeviljhoRightWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の唾液</a>
            <span>{String(data?.data.Deviljho[39][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DeviljhoHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の唾液</a>
            <span>{String(data?.data.Deviljho[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleBody />
        <div className="DeviljhoLeftWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[29][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の唾液</a>
            <span>{String(data?.data.Deviljho[29][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DeviljhoRightWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>恐暴竜の黒鱗</a>
            <span>{String(data?.data.Deviljho[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の黒皮</a>
            <span>{String(data?.data.Deviljho[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の尻尾</a>
            <span>{String(data?.data.Deviljho[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の鉤爪</a>
            <span>{String(data?.data.Deviljho[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の良質な皮</a>
            <span>{String(data?.data.Deviljho[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の頭殻</a>
            <span>{String(data?.data.Deviljho[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>恐暴竜の唾液</a>
            <span>{String(data?.data.Deviljho[40][24])}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Deviljhoimage} alt="" className="DeviljhoImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Deviljho;
