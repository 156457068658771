import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleLeftwing } from "../Components/ItemTitleLeftwing";
import { ItemTitleRightwing } from "../Components/ItemTitleRightwing";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import AzureRathalosimage from "../images/AzureRathalos.png";
interface AzureRathalosData {
  data: {
    AzureRathalos: string[][];
  };
}

const AzureRathalos: React.FC = () => {
  const [data, setData] = useState<AzureRathalosData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/azurerathalosdata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: AzureRathalosData) => {
      // 文字列に変換する
      const stringData: AzureRathalosData = {
        data: {
          AzureRathalos: responseData.data.AzureRathalos.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AzureRathalosHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[13][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[13][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[13][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[13][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[13][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="AzureRathalosBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[24][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="AzureRathalosLeftWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[35][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="AzureRathalosRightWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[46][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AzureRathalosTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[57][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[57][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[57][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[57][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[57][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AzureRathalosHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[14][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[14][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="AzureRathalosBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[25][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="AzureRathalosLeftWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[36][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="AzureRathalosRightWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[47][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[47][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AzureRathalosTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[58][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[58][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[58][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[58][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[58][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[58][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AzureRathalosHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[15][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[15][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="AzureRathalosBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[26][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="AzureRathalosLeftWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[37][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="AzureRathalosRightWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[48][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AzureRathalosTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[59][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[59][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[59][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[59][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[59][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[59][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AzureRathalosHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[16][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[16][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="AzureRathalosBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[27][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[27][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[27][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="AzureRathalosLeftWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="AzureRathalosRightWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[49][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[49][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AzureRathalosTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[60][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[60][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[60][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[60][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[60][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[60][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[60][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AzureRathalosHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[17][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[17][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="AzureRathalosBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[28][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[28][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="AzureRathalosLeftWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[39][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="AzureRathalosRightWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[50][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AzureRathalosTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[61][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[61][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[61][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[61][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[61][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[61][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[61][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="AzureRathalosHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="AzureRathalosBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[29][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[29][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="AzureRathalosLeftWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[40][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="AzureRathalosRightWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[51][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="AzureRathalosTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>蒼火竜の鱗</a>
            <span>{String(data?.data.AzureRathalos[62][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の翼爪</a>
            <span>{String(data?.data.AzureRathalos[62][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の尻尾</a>
            <span>{String(data?.data.AzureRathalos[62][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の骨髄</a>
            <span>{String(data?.data.AzureRathalos[62][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の良質な鱗</a>
            <span>{String(data?.data.AzureRathalos[62][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>蒼火竜の逆鱗</a>
            <span>{String(data?.data.AzureRathalos[62][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.AzureRathalos[62][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={AzureRathalosimage} alt="" className="AzureRathalosImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default AzureRathalos;
