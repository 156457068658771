import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import JyuratodusTable from "../MonsterTableComponents/JyuratodusTable";
import Jyuratodus from "../MonsterbreakComponents/Jyuratodus";

const Jyuratoduspage = () => {
    return (
        <div>
            <Header />
            <JyuratodusTable />
            <Jyuratodus />
            <Footer />
        </div>
    );
};

export default Jyuratoduspage