import React from 'react'
import "../assets/css/reset.css";
import "../assets/css/hero.css";
import iconHero from "../assets/images/icon_hero.png";

export const Hero = () => {
  return (
    <div className='hero'>
        <div className='hero__inner'>
            <span>
                <img className='iconHero' src={ iconHero } alt="" />
                <span>本サイトはモンハンNowの破壊部位別</span>
                <span>ドロップ率をまとめたサイトです</span>
            </span>
        </div>
    </div>
  )
}
