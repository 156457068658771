import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import PinkRathianTable from "../MonsterTableComponents/PinkRathianTable";
import PinkRathian from "../MonsterbreakComponents/PinkRathian";

const PinkRathianpage = () => {
    return (
        <div>
            <Header />
            <PinkRathianTable />
            <PinkRathian />
            <Footer />
        </div>
    );
};

export default PinkRathianpage