import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleLeftwing } from "../Components/ItemTitleLeftwing";
import { ItemTitleRightwing } from "../Components/ItemTitleRightwing";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import Rathianimage from "../images/Rathian.png";
interface RathianData {
  data: {
    Rathian: string[][];
  };
}

const Rathian: React.FC = () => {
  const [data, setData] = useState<RathianData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/rathiandata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: RathianData) => {
      // 文字列に変換する
      const stringData: RathianData = {
        data: {
          Rathian: responseData.data.Rathian.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathianHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[14][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathianBacklv4 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[26][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathianLeftWinglv4 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[38][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathianRightWinglv4 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[50][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathianTaillv4 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[62][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[62][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[62][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[62][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[62][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathianHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[15][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathianBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[27][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathianLeftWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[39][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathianRightWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[51][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathianTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[63][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[63][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[63][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[63][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[63][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathianHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[16][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathianBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[28][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathianLeftWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[40][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathianRightWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[52][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[52][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[52][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[52][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[52][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[52][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathianTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[64][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[64][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[64][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[64][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[64][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[64][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathianHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[17][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathianBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[29][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathianLeftWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[41][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[41][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[41][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[41][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[41][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[41][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathianRightWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[53][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[53][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[53][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[53][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[53][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[53][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathianTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[65][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[65][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[65][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[65][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[65][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[65][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathianHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathianBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[30][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[30][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[30][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[30][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[30][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[30][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[30][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathianLeftWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[42][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[42][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[42][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[42][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[42][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[42][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[42][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathianRightWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[54][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[54][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[54][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[54][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[54][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[54][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[54][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathianTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[66][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[66][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[66][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[66][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[66][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[66][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[66][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathianHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[19][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[19][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[19][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[19][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathianBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[31][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[31][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[31][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[31][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[31][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[31][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[31][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathianLeftWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[43][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[43][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[43][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[43][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[43][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[43][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[43][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathianRightWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[55][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[55][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[55][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[55][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[55][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[55][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[55][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathianTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[67][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[67][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[67][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[67][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[67][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[67][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[67][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathianHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[20][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[20][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[20][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathianBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[32][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[32][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[32][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[32][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[32][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathianLeftWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[44][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[44][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[44][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[44][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[44][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[44][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[44][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathianRightWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[56][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[56][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[56][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[56][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[56][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[56][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[56][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathianTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>雌火竜の鱗</a>
            <span>{String(data?.data.Rathian[68][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の甲殻</a>
            <span>{String(data?.data.Rathian[68][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の翼膜</a>
            <span>{String(data?.data.Rathian[68][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の棘</a>
            <span>{String(data?.data.Rathian[68][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の良質な鱗</a>
            <span>{String(data?.data.Rathian[68][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>雌火竜の逆鱗</a>
            <span>{String(data?.data.Rathian[68][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathian[68][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Rathianimage} alt="" className="RathianImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Rathian;
