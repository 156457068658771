import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import OdogaronTable from "../MonsterTableComponents/OdogaronTable";
import Odogaron from "../MonsterbreakComponents/Odogaron";

const odogaronpage = () => {
    return (
        <div>
            <Header />
            <OdogaronTable />
            <Odogaron />
            <Footer />
        </div>
    );
};

export default odogaronpage