import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleLeftspike } from "../Components/ItemTitleLeftspike";
import { ItemTitleRightspike } from "../Components/ItemTitleRightspike";
import Bariothimage from "../images/Barioth.png";
interface BariothData {
  data: {
    Barioth: string[][];
  };
}

const Barioth: React.FC = () => {
  const [data, setData] = useState<BariothData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/bariothdata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: BariothData) => {
      // 文字列に変換する
      const stringData: BariothData = {
        data: {
          Barioth: responseData.data.Barioth.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>Please select an option</span></div>;

  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BariothHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[14][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftspike />
        <div className="BariothLeftSpikelv4 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[26][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightspike />
        <div className="BariothRightSpikelv4 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[38][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BariothTaillv4 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[50][18])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BariothHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[15][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftspike />
        <div className="BariothLeftSpikelv5 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[27][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightspike />
        <div className="BariothRightSpikelv5 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[39][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BariothTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[51][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BariothHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[16][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftspike />
        <div className="BariothLeftSpikelv6 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[28][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightspike />
        <div className="BariothRightSpikelv6 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[40][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BariothTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[52][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[52][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[52][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[52][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[52][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[52][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BariothHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[17][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftspike />
        <div className="BariothLeftSpikelv7 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[29][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightspike />
        <div className="BariothRightSpikelv7 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[41][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[41][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[41][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[41][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[41][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[41][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BariothTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[53][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[53][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[53][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[53][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[53][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[53][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BariothHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[18][24])}</span>
          </div>          
        </div>
        
        < ItemTitleLeftspike />
        <div className="BariothLeftSpikelv8 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[30][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[30][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[30][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[30][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[30][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[30][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[30][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightspike />
        <div className="BariothRightSpikelv8 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[42][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[42][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[42][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[42][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[42][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[42][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[42][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BariothTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[54][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[54][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[54][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[54][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[54][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[54][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[54][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BariothHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[19][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[19][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[19][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[19][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftspike />
        <div className="BariothLeftSpikelv9 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[31][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[31][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[31][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[31][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[31][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[31][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[31][24])}</span>
          </div>          
        </div>
        
        < ItemTitleRightspike />
        <div className="BariothRightSpikelv9 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[43][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[43][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[43][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[43][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[43][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[43][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[43][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BariothTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[55][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[55][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[55][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[55][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[55][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[55][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[55][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BariothHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[20][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[20][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[20][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftspike />
        <div className="BariothLeftSpikelv10 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[32][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[32][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[32][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[32][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[32][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightspike />
        <div className="BariothRightSpikelv10 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[44][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[44][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[44][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[44][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[44][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[44][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[44][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BariothTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>氷牙竜の爪</a>
            <span>{String(data?.data.Barioth[56][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の甲殻</a>
            <span>{String(data?.data.Barioth[56][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の尻尾</a>
            <span>{String(data?.data.Barioth[56][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の棘</a>
            <span>{String(data?.data.Barioth[56][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>氷牙竜の良質な爪</a>
            <span>{String(data?.data.Barioth[56][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>琥珀色の牙</a>
            <span>{String(data?.data.Barioth[56][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barioth[56][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Bariothimage} alt="" className="BariothImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Barioth;
