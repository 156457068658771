import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import BanbaroTable from "../MonsterTableComponents/BanbaroTable";
import Banbaro from "../MonsterbreakComponents/Banbaro";

const Banbaropage = () => {
    return (
        <div>
            <Header />
            <BanbaroTable />
            <Banbaro />
            <Footer />
        </div>
    );
};

export default Banbaropage