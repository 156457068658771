import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import CoralpukeiTable from "../MonsterTableComponents/CoralpukeiTable";
import Coralpukei from "../MonsterbreakComponents/Coralpukei";

const Coralpukeipage = () => {
    return (
        <div>
            <Header />
            <CoralpukeiTable />
            <Coralpukei />
            <Footer />
        </div>
    );
};

export default Coralpukeipage