import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import RathianTable from "../MonsterTableComponents/RathianTable";
import Rathian from "../MonsterbreakComponents/Rathian";

const Rathianpage = () => {
    return (
        <div>
            <Header />
            <RathianTable />
            <Rathian />
            <Footer />
        </div>
    );
};

export default Rathianpage