import React from 'react'
import { DecolationShort } from "../Components/DecolationShort";

export const ItemTitleHead2 = () => {
  return (
    <div className="dropRate__title">
        <h5>頭2段階</h5>
        <span>< DecolationShort /></span>
    </div>
  )
}
