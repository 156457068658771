import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import TzitziyakuTable from "../MonsterTableComponents/TzitziYakuTable";
import Tzitziyaku from "../MonsterbreakComponents/TzitziYaku";

const Tzitziyakupage = () => {
    return (
        <div>
            <Header />
            <TzitziyakuTable />
            <Tzitziyaku />
            <Footer />
        </div>
    );
};

export default Tzitziyakupage