import React from 'react'
import "../assets/css/reset.css";
import "../assets/css/footer.css";
import { Link, } from "react-router-dom";

export const Footer = () => {
  return (
    <div className='footer'>

        <div className='footer__inner'>
            <span>
                <Link to ="/privacy">プライバシーポリシー</Link>
                <span className="decolation__line"></span>
                <a href="https://forms.gle/JDZahVs9HYCpT5KB7" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
            </span>
            <small>&copy; 2024 モンハンNow攻略</small>
        </div>
    </div>
  )
}
