import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleLeftwing } from "../Components/ItemTitleLeftwing";
import { ItemTitleRightwing } from "../Components/ItemTitleRightwing";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import PinkRathianimage from "../images/PinkRathian.png";
interface PinkRathianData {
  data: {
    PinkRathian: string[][];
  };
}

const PinkRathian: React.FC = () => {
  const [data, setData] = useState<PinkRathianData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/pinkrathiandata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: PinkRathianData) => {
      // 文字列に変換する
      const stringData: PinkRathianData = {
        data: {
          PinkRathian: responseData.data.PinkRathian.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PinkRathianHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[13][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[13][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[13][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[13][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[13][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PinkRathianBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[24][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PinkRathianLeftWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[35][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PinkRathianRightWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[46][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PinkRathianTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[57][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[57][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[57][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[57][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[57][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PinkRathianHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[14][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[14][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PinkRathianBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[25][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PinkRathianLeftWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[36][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PinkRathianRightWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[47][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[47][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PinkRathianTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[58][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[58][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[58][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[58][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[58][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[58][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PinkRathianHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[15][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[15][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PinkRathianBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[26][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PinkRathianLeftWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[37][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PinkRathianRightWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[48][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PinkRathianTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[59][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[59][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[59][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[59][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[59][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[59][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PinkRathianHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[16][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[16][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PinkRathianBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[27][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[27][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[27][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PinkRathianLeftWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PinkRathianRightWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[49][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[49][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PinkRathianTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[60][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[60][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[60][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[60][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[60][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[60][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[60][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PinkRathianHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[17][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[17][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PinkRathianBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[28][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[28][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PinkRathianLeftWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[39][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PinkRathianRightWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[50][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PinkRathianTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[61][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[61][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[61][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[61][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[61][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[61][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[61][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PinkRathianHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PinkRathianBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[29][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[29][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PinkRathianLeftWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[40][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PinkRathianRightWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[51][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PinkRathianTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>桜火竜の鱗</a>
            <span>{String(data?.data.PinkRathian[62][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の甲殻</a>
            <span>{String(data?.data.PinkRathian[62][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の翼膜</a>
            <span>{String(data?.data.PinkRathian[62][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の棘</a>
            <span>{String(data?.data.PinkRathian[62][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の良質な鱗</a>
            <span>{String(data?.data.PinkRathian[62][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>桜火竜の逆鱗</a>
            <span>{String(data?.data.PinkRathian[62][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.PinkRathian[62][24])}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={PinkRathianimage} alt="" className="PinkRathianImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default PinkRathian;
