import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleForelegs } from "../Components/ItemTitleForelegs";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import Dosgirrosimage from "../images/GreatGirros.png";
interface DosgirrosData {
  data: {
    Dosgirros: string[][];
  };
}

const Dosgirros: React.FC = () => {
  const [data, setData] = useState<DosgirrosData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/dosgirrosdata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: DosgirrosData) => {
      // 文字列に変換する
      const stringData: DosgirrosData = {
        data: {
          Dosgirros: responseData.data.Dosgirros.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level2") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosgirrosHeadlv2 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[16][15])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="DosgirrosForelegslv2 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[30][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[30][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[30][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[30][15])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DosgirrosTaillv2 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[44][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[44][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[44][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[44][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level3") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosgirrosHeadlv3 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[17][15])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="DosgirrosForelegslv3 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[31][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[31][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[31][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[31][15])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DosgirrosTaillv3 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[45][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[45][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[45][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[45][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosgirrosHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[18][18])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="DosgirrosForelegslv4 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[32][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[32][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[32][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DosgirrosTaillv4 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[46][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosgirrosHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[19][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[19][18])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="DosgirrosForelegslv5 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[33][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[33][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[33][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[33][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[33][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DosgirrosTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[47][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosgirrosHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[20][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[20][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="DosgirrosForelegslv6 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[34][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[34][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[34][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[34][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[34][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[34][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DosgirrosTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[48][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosgirrosHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[21][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[21][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[21][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[21][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[21][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[21][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="DosgirrosForelegslv7 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[35][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[35][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DosgirrosTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[49][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosgirrosHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[22][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[22][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[22][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[22][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[22][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[22][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosgirros[22][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="DosgirrosForelegslv8 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[36][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosgirros[36][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DosgirrosTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosgirros[50][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosgirrosHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[23][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[23][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[23][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[23][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[23][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[23][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosgirros[23][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="DosgirrosForelegslv9 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[37][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosgirros[37][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DosgirrosTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosgirros[51][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosgirrosHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[24][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[24][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosgirros[24][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="DosgirrosForelegslv10 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosgirros[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DosgirrosTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>痺賊竜の鱗</a>
            <span>{String(data?.data.Dosgirros[52][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の牙</a>
            <span>{String(data?.data.Dosgirros[52][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の尻尾</a>
            <span>{String(data?.data.Dosgirros[52][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の頭巾殻</a>
            <span>{String(data?.data.Dosgirros[52][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosgirros[52][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>痺賊竜の良質な牙</a>
            <span>{String(data?.data.Dosgirros[52][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosgirros[52][24])}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level2">★2</option>
            <option value="level3">★3</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Dosgirrosimage} alt="" className="DosgirrosImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Dosgirros;
