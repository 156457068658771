import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleForelegs } from "../Components/ItemTitleForelegs";
import { ItemTitleLefthindleg } from "../Components/ItemTitleLefthindleg";
import { ItemTitleRighthindleg } from "../Components/ItemTitleRighthindleg";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import Barrothimage from "../images/Barroth.png";
interface BarrothData {
  data: {
    Barroth: string[][];
  };
}

const Barroth: React.FC = () => {
  const [data, setData] = useState<BarrothData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/barrothdata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: BarrothData) => {
      // 文字列に変換する
      const stringData: BarrothData = {
        data: {
          Barroth: responseData.data.Barroth.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level1") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BarrothHeadlv1 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[17][12])}</span>
          </div> 
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv1 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[32][12])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv1 dropRate__box">
          <div className="dropRate__item">
           <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[47][6])}</span>
          </div>
          <div className="dropRate__item">
          <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[47][9])}</span>
          </div>
          <div className="dropRate__item">
          <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[47][12])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv1 dropRate__box">
          <div className="dropRate__item">
          <a>土砂竜の甲殻</a>
          <span>{String(data?.data.Barroth[62][6])}</span>
          </div>
          <div className="dropRate__item">
          <a>土砂竜の爪</a>
          <span>{String(data?.data.Barroth[62][9])}</span>
          </div>
          <div className="dropRate__item">
          <a>土砂竜の尻尾</a>
          <span>{String(data?.data.Barroth[62][12])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv1 dropRate__box">
          <div className="dropRate__item">
            
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[77][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[77][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[77][12])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level2") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BarrothHeadlv2 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[18][15])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv2 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[33][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[33][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[33][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[33][15])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv2 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[48][15])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv2 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[63][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[63][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[63][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[63][15])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv2 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[78][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[78][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[78][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[78][15])}</span>
          </div>
        </div>  
      </div>
    );
  }
  if (selectedOption === "level3") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BarrothHeadlv3 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[19][15])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv3 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[34][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[34][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[34][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[34][15])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv3 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[49][15])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv3 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[64][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[64][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[64][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[64][15])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv3 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[79][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[79][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[79][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[79][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BarrothHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[20][18])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv4 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[35][18])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv4 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[50][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv4 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[65][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[65][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[65][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[65][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[65][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv4 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[80][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[80][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[80][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[80][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[80][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="BarrothHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[21][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[21][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[21][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[21][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[21][18])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv5 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[36][18])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv5 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[51][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv5 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[66][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[66][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[66][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[66][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[66][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[81][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[81][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[81][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[81][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[81][18])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div className="Barroth">

        < ItemTitleHead />
        <div className="BarrothHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[22][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[22][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[22][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[22][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[22][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[22][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv6 dropRate__box">
         <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[37][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv6 dropRate__box">
         <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[52][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[52][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[52][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[52][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[52][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[52][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv6 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[67][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[67][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[67][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[67][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[67][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[67][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[82][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[82][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[82][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[82][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[82][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[82][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div className="Barroth">

        < ItemTitleHead />
        <div className="BarrothHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[23][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[23][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[23][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[23][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[23][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[23][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv7 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[38][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv7 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[53][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[53][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[53][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[53][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[53][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[53][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv7 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[68][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[68][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[68][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[68][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[68][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[22][21])}</span>
          </div>
          
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[83][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[83][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[83][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[83][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[83][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[83][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div className="Barroth">

        < ItemTitleHead />
        <div className="BarrothHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[24][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[24][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[24][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv8 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[39][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv8 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[54][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[54][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[54][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[54][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[54][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[54][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[54][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv8 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[69][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[69][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[69][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[69][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[69][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[69][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[69][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[84][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[84][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[84][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[84][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[84][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[84][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[84][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div className="Barroth">

        < ItemTitleHead />
        <div className="BarrothHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[25][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[25][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv9 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[40][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv9 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[55][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[55][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[55][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[55][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[55][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[55][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[55][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv9 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[70][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[70][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[70][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[70][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[70][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[70][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[70][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[85][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[85][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[85][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[85][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[85][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[85][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[85][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div className="Barroth">

        < ItemTitleHead />
        <div className="BarrothHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[26][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[26][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="BarrothForelegslv10 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[41][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[41][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[41][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[41][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[41][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[41][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[41][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BarrothLeftHindleglv10 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[56][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[56][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[56][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[56][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[56][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[56][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[56][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BarrothRightHindleglv10 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[71][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[71][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[71][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[71][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[71][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[71][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[71][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BarrothTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>土砂竜の甲殻</a>
            <span>{String(data?.data.Barroth[86][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の爪</a>
            <span>{String(data?.data.Barroth[86][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の尻尾</a>
            <span>{String(data?.data.Barroth[86][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の頭殻</a>
            <span>{String(data?.data.Barroth[86][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の背甲</a>
            <span>{String(data?.data.Barroth[86][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>土砂竜の良質な甲殻</a>
            <span>{String(data?.data.Barroth[86][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Barroth[86][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level1">★1</option>
            <option value="level2">★2</option>
            <option value="level3">★3</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Barrothimage} alt="" className="BarrothImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Barroth;
