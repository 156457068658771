import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleLefthindleg } from "../Components/ItemTitleLefthindleg";
import { ItemTitleRighthindleg } from "../Components/ItemTitleRighthindleg";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleLefthorn } from "../Components/ItemTitleLefthorn";
import { ItemTitleRighthorn } from "../Components/ItemTitleRighthorn";
import Banbaroimage from "../images/Banbaro.png";
interface BanbaroData {
  data: {
    Banbaro: string[][];
  };
}

const Banbaro: React.FC = () => {
  const [data, setData] = useState<BanbaroData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/banbarodata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: BanbaroData) => {
      // 文字列に変換する
      const stringData: BanbaroData = {
        data: {
          Banbaro: responseData.data.Banbaro.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level3") {
    content = (
      <div>

        < ItemTitleLefthorn />
        <div className="BanbaroLeftHornlv3 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[15][15])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BanbaroRightHornlv3 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[28][15])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BanbaroLeftHindleglv3 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[41][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[41][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[41][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[41][15])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BanbaroRightHindleglv3 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[54][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[54][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[54][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[54][15])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BanbaroTaillv3 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[67][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[67][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[67][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[67][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div className="Banbaro">

        < ItemTitleLefthorn />
        <div className="BanbaroLeftHornlv4 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[16][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BanbaroRightHornlv4 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[29][18])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BanbaroLeftHindleglv4 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[42][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[42][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[42][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[42][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[42][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BanbaroRightHindleglv4 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[55][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[55][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[55][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[55][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[55][18])}</span>
          </div>
        </div>

        < ItemTitleTail />
        <div className="BanbaroTaillv4 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[68][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[68][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[68][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[68][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[68][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div className="Banbaro">

        < ItemTitleLefthorn />
        <div className="BanbaroLeftHornlv5 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[17][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BanbaroRightHornlv5 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[30][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[30][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[30][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[30][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[30][18])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BanbaroLeftHindleglv5 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[43][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[43][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[43][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[43][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[43][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BanbaroRightHindleglv5 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[56][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[56][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[56][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[56][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[56][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BanbaroTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[69][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[69][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[69][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[69][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[69][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div className="Banbaro">

        < ItemTitleLefthorn />
        <div className="BanbaroLeftHornlv6 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[18][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BanbaroRightHornlv6 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[31][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[31][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[31][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[31][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[31][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[31][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BanbaroLeftHindleglv6 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[44][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[44][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[44][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[44][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[44][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[44][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BanbaroRightHindleglv6 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[57][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[57][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[57][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[57][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[57][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[57][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BanbaroTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[70][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[70][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[70][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[70][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[70][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[70][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div className="Banbaro">

        < ItemTitleLefthorn />
        <div className="BanbaroLeftHornlv7 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[19][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[19][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[19][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BanbaroRightHornlv7 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[32][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[32][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[32][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[32][21])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BanbaroLeftHindleglv7 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[45][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[45][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[45][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[45][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[45][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[45][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BanbaroRightHindleglv7 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[58][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[58][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[58][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[58][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[58][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[58][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BanbaroTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[71][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[71][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[71][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[71][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[71][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[71][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div className="Banbaro">

        < ItemTitleLefthorn />
        <div className="BanbaroLeftHornlv8 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[20][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[20][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[20][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BanbaroRightHornlv8 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[33][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[33][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[33][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[33][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[33][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[33][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[33][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BanbaroLeftHindleglv8 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[46][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[46][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[46][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BanbaroRightHindleglv8 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[59][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[59][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[59][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[59][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[59][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[59][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[59][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BanbaroTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[72][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[72][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[72][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[72][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[72][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[72][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[72][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div className="Banbaro">

        < ItemTitleLefthorn />
        <div className="BanbaroLeftHornlv9 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[21][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[21][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[21][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[21][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[21][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[21][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[21][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BanbaroRightHornlv9 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[34][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[34][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[34][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[34][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[34][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[34][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[34][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BanbaroLeftHindleglv9 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[47][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[47][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[47][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BanbaroRightHindleglv9 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[60][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[60][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[60][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[60][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[60][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[60][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[60][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BanbaroTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[73][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[73][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[73][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[73][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[73][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[73][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[73][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div className="Banbaro">

        < ItemTitleLefthorn />
        <div className="BanbaroLeftHornlv10 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[22][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[22][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[22][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[22][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[22][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[22][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[22][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BanbaroRightHornlv10 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[35][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[35][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[35][24])}</span>
          </div>
        </div>
        
        < ItemTitleLefthindleg />
        <div className="BanbaroLeftHindleglv10 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[48][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[48][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthindleg />
        <div className="BanbaroRightHindleglv10 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[61][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[61][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[61][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[61][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[61][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[61][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[61][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BanbaroTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>猛牛竜の甲殻</a>
            <span>{String(data?.data.Banbaro[74][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の背甲</a>
            <span>{String(data?.data.Banbaro[74][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の尻尾</a>
            <span>{String(data?.data.Banbaro[74][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の毛皮</a>
            <span>{String(data?.data.Banbaro[74][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の良質な甲殻</a>
            <span>{String(data?.data.Banbaro[74][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>猛牛竜の巨大な角</a>
            <span>{String(data?.data.Banbaro[74][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Banbaro[74][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level3">★3</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Banbaroimage} alt="" className="BanbaroImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Banbaro;
