import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleForelegs } from "../Components/ItemTitleForelegs";
import KuluYakuimage from "../images/KuluYaku.png";
interface KuluYakuData {
  data: {
    KuluYaku: string[][];
  };
}

const KuluYaku: React.FC = () => {
  const [data, setData] = useState<KuluYakuData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

    useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/kuluYakudata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: KuluYakuData) => {
      // 文字列に変換する
      const stringData: KuluYakuData = {
        data: {
          KuluYaku: responseData.data.KuluYaku.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);


  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level1") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv1 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[17][12])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv1 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[32][12])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level2") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv2 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[18][15])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv2 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[33][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[33][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[33][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[33][15])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level3") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv3 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[19][15])}</span>
          </div>          
        </div>
        
        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv3 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[34][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[34][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[34][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[34][15])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[20][18])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv4 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[35][18])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[21][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[21][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[21][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[21][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[21][18])}</span>
          </div>          
        </div>
        
        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv5 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[36][18])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[22][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[22][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[22][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[22][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[22][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[22][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv6 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[37][21])}</span>
          </div>          
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[23][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[23][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[23][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[23][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[23][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[23][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv7 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[38][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[24][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[24][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.KuluYaku[24][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv8 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.KuluYaku[39][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[25][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.KuluYaku[25][24])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv9 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.KuluYaku[40][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="KuluYakuHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[26][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.KuluYaku[26][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="KuluYakuForelegslv10 dropRate__box">
          <div className="dropRate__item">
            <a>掻鳥の鱗</a>
            <span>{String(data?.data.KuluYaku[41][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の皮</a>
            <span>{String(data?.data.KuluYaku[41][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥のクチバシ</a>
            <span>{String(data?.data.KuluYaku[41][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の飾り羽</a>
            <span>{String(data?.data.KuluYaku[41][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な鱗</a>
            <span>{String(data?.data.KuluYaku[41][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>掻鳥の良質な皮</a>
            <span>{String(data?.data.KuluYaku[41][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.KuluYaku[41][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>

        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level1">★1</option>
            <option value="level2">★2</option>
            <option value="level3">★3</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={KuluYakuimage} alt="" className="KuluYakuImage" />
        </div>

        {content}
      </div>
      
    </div>
  );
};

export default KuluYaku;
