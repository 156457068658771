import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import TobikadachiTable from "../MonsterTableComponents/TobikadachiTable";
import Tobikadachi from "../MonsterbreakComponents/Tobikadachi";

const Tobikadachipage = () => {
    return (
        <div>
            <Header />
            <TobikadachiTable />
            <Tobikadachi />
            <Footer />
        </div>
    );
};

export default Tobikadachipage