import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleLeftwing } from "../Components/ItemTitleLeftwing";
import { ItemTitleRightwing } from "../Components/ItemTitleRightwing";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import Rathalosimage from "../images/Rathalos.png";
interface RathalosData {
  data: {
    Rathalos: string[][];
  };
}

const Rathalos: React.FC = () => {
  const [data, setData] = useState<RathalosData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/rathalosdata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: RathalosData) => {
      // 文字列に変換する
      const stringData: RathalosData = {
        data: {
          Rathalos: responseData.data.Rathalos.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathalosHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[13][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[13][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[13][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[13][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[13][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathalosBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[24][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathalosLeftWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[35][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathalosRightWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[46][18])}</span>
          </div>          
        </div>
        
        < ItemTitleTail />
        <div className="RathalosTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[57][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[57][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[57][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[57][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[57][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathalosHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[14][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[14][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathalosBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[25][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathalosLeftWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[36][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathalosRightWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[47][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[47][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathalosTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[58][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[58][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[58][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[58][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[58][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[58][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathalosHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[15][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[15][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathalosBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[26][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathalosLeftWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[37][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathalosRightWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[48][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathalosTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[59][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[59][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[59][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[59][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[59][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[59][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathalosHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[16][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[16][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathalosBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[27][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[27][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[27][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathalosLeftWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathalosRightWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[49][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[49][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathalosTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[60][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[60][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[60][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[60][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[60][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[60][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[60][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathalosHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[17][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[17][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathalosBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[28][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[28][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathalosLeftWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[39][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathalosRightWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[50][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathalosTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[61][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[61][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[61][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[61][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[61][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[61][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[61][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="RathalosHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="RathalosBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[29][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[29][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="RathalosLeftWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[40][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="RathalosRightWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[51][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="RathalosTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>火竜の鱗</a>
            <span>{String(data?.data.Rathalos[62][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の翼爪</a>
            <span>{String(data?.data.Rathalos[62][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の尻尾</a>
            <span>{String(data?.data.Rathalos[62][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の骨髄</a>
            <span>{String(data?.data.Rathalos[62][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の良質な鱗</a>
            <span>{String(data?.data.Rathalos[62][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>火竜の逆鱗</a>
            <span>{String(data?.data.Rathalos[62][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Rathalos[62][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Rathalosimage} alt="" className="RathalosImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Rathalos;
