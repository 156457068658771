import React from "react";
import "../assets/css/reset.css";
import "../assets/css/privacy.css";

const terms = () => {
  return (
    <div className="page__wrap" id="privacy__wrap">
      <div className="wapper">
        <div className="content__wrap">
          <div className="title">プライバシーポリシー</div>
          <div></div>
          <div className="content">
            <div>
              <h1 className="title__in__content">プライバシーポリシー</h1>
              <p className="privacy__txt">
                株式会社ナミプロ（以下「当社」といいます。）は、個人情報保護の重要性を認識し、その適切な管理を行うことを重要な社会的責務と考え、この責務を果たすために、個人情報の保護に関する法律その他の適用ある法令等を遵守し、個人情報の保護に努めます。
              </p>
              <p className="privacy__txt">
                このプライバシーポリシー（以下「本ポリシー」といいます。）は、当社が提供する「モンハンNow部位破壊ドロップDB」（以下「本サービス」といいます。）における、ユーザーの個人情報を含む利用者情報の取扱いについて、以下の事項を定めるものです。
              </p>
              <ul className="privacy__list">
                <li className="privacy__list-item">
                  （１）当社が収集する情報の範囲及び収集方法
                </li>
                <li className="privacy__list-item">
                  （２）当社が収集した情報の利用目的と利用方法
                </li>
                <li className="privacy__list-item">
                  （３）当社が収集した情報の第三者提供
                </li>
                <li className="privacy__list-item">
                  （４）個人情報の開示・訂正・利用停止
                </li>
              </ul>
              <h2 className="privacy__clause">
                第１条（当社が収集する情報の範囲及び収集方法）
              </h2>
              <ul className="privacy__list">
                <li className="privacy__list-item">
                  １．本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他印刷物の発注に関連し生成または蓄積された情報であって、本ポリシーに基づき当社が収集する情報を意味するものとします。
                </li>
                <li className="privacy__list-item">
                  ２．本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて以下のとおりです。
                  <ul className="privacy__list">
                    <li className="privacy__list-item">
                      （１）ユーザーからご提供いただく情報
                      <ul className="privacy__list">
                        <li className="privacy__list-item">① メールアドレス</li>
                        <li className="privacy__list-item">② 住所地</li>
                        <li className="privacy__list-item">③ 電話番号</li>
                        <li className="privacy__list-item">④ 生年月日</li>
                        <li className="privacy__list-item">⑤ 性別</li>
                        <li className="privacy__list-item">
                          ⑥
                          その他当社が定める入力フォームにユーザーが入力する情報
                        </li>
                      </ul>
                    </li>
                    <li className="privacy__list-item">
                      （２）ユーザーが本サービスを利用するにあたって当社が収集する情報
                      <ul className="privacy__list">
                        <li className="privacy__list-item">
                          ① 端末に関する情報
                        </li>
                        <li className="privacy__list-item">
                          ② アクセスログに関する情報
                        </li>
                        <li className="privacy__list-item">
                          ③ Cookieに関する情報
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <h2 className="privacy__clause">
                第２条（利用者情報の外部送信について）
              </h2>
              <p className="privacy__txt">
                ユーザーが本サービスを利用するために当社が管理するウェブサイトにアクセスした場合、情報収集モジュールやCookie等を利用し、ユーザーに応じた本サービス内容のカスタマイズ、広告・マーケティング等の目的で、利用者情報の外部送信を行っています。
              </p>
              <ul className="privacy__list">
                <li className="privacy__list-item">
                  <p className="privacy__txt">Google 広告</p>
                  <a
                    href="https://policies.google.com/privacy?hl=ja"
                    target="_blank"
                    rel="nofollow"
                    className="policies__link"
                  >
                    https://policies.google.com/privacy?hl=ja
                  </a>
                </li>
              </ul>
              <h2 className="privacy__clause">
                第３条（当社が収集した情報の利用目的）
              </h2>
              <p className="privacy__txt">
                利用者情報の利用目的は以下のとおりです。
              </p>
              <ul className="privacy__list">
                <li className="privacy__list-item">
                  ①
                  本サービスに関する登録の受付、本人確認、電話番号によるSMS認証、本サービスの提供、維持、保護、及び改善のため
                </li>
                <li className="privacy__list-item">
                  ② 本サービスに関するご案内、お問い合わせ等への対応のため
                </li>
                <li className="privacy__list-item">
                  ③
                  本サービスに関する当社の規約、本ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
                </li>
                <li className="privacy__list-item">
                  ④ 本サービスに関する規約等の変更等を通知するため
                </li>
                <li className="privacy__list-item">
                  ⑤
                  本サービスに関連して、個人を識別できない形式に加工した統計データを作成するため
                </li>
                <li className="privacy__list-item">
                  ⑥ 当社または第三者の広告の配信または表示のため
                </li>
                <li className="privacy__list-item">
                  ⑦ その他マーケティングに利用するため
                </li>
                <li className="privacy__list-item">
                  ⑧ 上記利用目的に付随する行為のため
                </li>
              </ul>
              <h2 className="privacy__clause">
                第４条（当社が収集した情報の第三者への開示）
              </h2>
              <p className="privacy__txt">
                当社は、利用者情報のうち、個人情報については、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめユーザーの同意を得ないで第三者に提供しません。ただし、次に掲げる場合はこの限りではありません。
              </p>
              <ul className="privacy__list">
                <li className="privacy__list-item">
                  ①
                  当社が利用目的の達成に必要な範囲内において利用者情報の取扱いの全部または一部を委託する場合
                </li>
                <li className="privacy__list-item">
                  ②
                  合併その他の事由による事業の承継に伴って利用者情報が提供される場合
                </li>
                <li className="privacy__list-item">
                  ③
                  利用者情報を特定の者との間で共同して利用する場合であって、その旨ならびに共同して利用される利用者情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該利用者情報の管理について責任を有する者の氏名又は名称、住所（法人にあっては代表者名）について、あらかじめご本人に通知し、又はご本人が容易に知り得る状態に置いているとき
                </li>
              </ul>
              <h2 className="privacy__clause">第５条（安全管理措置）</h2>
              <p className="privacy__txt">
                当社は、利用者情報を、第三者がアクセスできない環境下にあるサーバにて安全に保管し、利用者情報の漏えい、紛失、破壊、改ざん、不正アクセスが生じないように厳重なセキュリティ対策を講じます。
              </p>
              <h2 className="privacy__clause">第６条（個人情報の開示）</h2>
              <ul className="privacy__list">
                <li className="privacy__list-item">
                  １．当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を請求されたときは、ユーザーご本人からのご請求であることを確認した上で、ユーザーに対し、遅滞なく個人情報を開示します（当該個人情報が存在しないときはその旨を通知します）。ただし、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。また、個人情報に該当しない利用者情報については、原則として開示いたしません。
                </li>
                <li className="privacy__list-item">
                  ２．個人情報の開示につきましては、手数料（１件あたり１０００円）をいただきます。
                </li>
              </ul>
              <h2 className="privacy__clause">
                第７条（個人情報の訂正及び利用停止等）
              </h2>
              <li className="privacy__list-item">
                １．当社は、個人情報の訂正及び利用停止等を請求されたときは、ユーザーご本人からのご請求であることを確認した上で必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止等を行い、その旨をユーザーに通知します。ただし、合理的な理由に基づいて訂正または利用停止等を行わない旨の決定をしたときは、ユーザーに対しその旨を通知します。
              </li>
              <li className="privacy__list-item">
                ２．前項は、個人情報保護法その他の法令により当社が訂正または利用停止等の義務を負わない場合には適用しません。
              </li>
              <li className="privacy__list-item">
                ３．第1項の規定に関わらず、個人情報の訂正または利用停止等に多額の費用を有する場合その他訂正または利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要な代替措置をとることができるときは、訂正または利用停止等に代えて当該代替策措置を講じるものとします。
              </li>
              <h2 className="privacy__clause">
                第８条（プライバシーポリシーの変更手続）
              </h2>
              <p className="privacy__txt">
                当社は、利用者情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて本ポリシーを変更することがあります。
                <br />
                本ポリシーを変更した場合には、変更後の本ポリシーについて通知するものとします。ただし、本ポリシーの変更につき、法令上ユーザーの同意が必要な場合には、当社所定の方法でユーザーの同意を得るものとします。
              </p>
              <h2 className="privacy__clause">第９条（お問い合わせ窓口）</h2>
              <p className="privacy__txt">
                ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。
              </p>
              <p className="privacy__txt">会社名:株式会社ナミプロ</p>
              <br />
              <p className="privacy__txt">
                当社の住所および代表者の氏名は、当社会社概要をご参照ください。
              </p>
              <p className="privacy__txt">
                <a
                  href="https://nami-pro.com/"
                  target="_blank"
                  rel="nofollow"
                  className="company__link"
                >
                  https://nami-pro.com/
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default terms;
