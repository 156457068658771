import React, { useState, useEffect } from 'react';
import { Decolation } from "../Components/Decolation";
import Rathianimage from "../images/Rathian.png";
import IconImage from "../assets/images/icon_tittle.png";
import "../assets/css/reset.css";
import "../assets/css/monstertable.css";
import "../assets/css/monsterpage_base.css";

interface RathianData {
    data: {
      Rathian: string[][];
    };
  }
  
  const RathianTable: React.FC = () => {
    const [data, setData] = useState<RathianData | null>(null);
  
    useEffect(() => {
      fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/rathiandata", {
        method: "GET",
        mode: "cors", // CORS を有効にする
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((jsonData) => {
        console.log('Fetched Data:', jsonData);
        // データを文字列に変換する
        const stringData: RathianData = {
          data: {
            Rathian: jsonData.data.Rathian.map((row: any[]) =>
              row.map((cell: any) => String(cell))
            ),
          },
        };
        setData(stringData);
      })
      .catch((error) => console.error("データの取得中にエラーが発生しました:", error));
    }, []);
  
  if (!data || !data.data || !data.data.Rathian) {
    return <div className='loading'><span>Loading...</span></div>;
  }
  

    const specificData = data.data.Rathian
      // インデックスの調整
      .slice(2, 9)
      .map((item) => [item[1], item[6], item[9], item[12], item[15], item[18], item[21], item[24]]);
  
    return (
      <div className='monsterpage'>

        <div className='monster__name'>
          <img src={IconImage} alt="" className="Nameicon" />
          <h3>リオレイア</h3>
        </div>


        <div className='monster__topImage'>
          <img src={Rathianimage} alt="" className="RathianImage" />
        </div>

        <div className='monster__table'>
          <div className='monsterpage__title'>
            <h4><span>基本報酬</span><span>ドロップ率</span></h4>
            <span>< Decolation /></span>
          </div>

          <table border={1} className='block__table'>
            <thead className='block__thead'>
              <tr className='block__table-row'>
                <th className='block__table-head'></th>
                <th className='block__table-head'>レア1</th>
                <th className='block__table-head'>レア1</th>
                <th className='block__table-head'>レア2</th>
                <th className='block__table-head'>レア3</th>
                <th className='block__table-head'>レア4</th>
                <th className='block__table-head'>レア5</th>
                <th className='block__table-head'>レア6</th>
              </tr>
              <tr className='block__table-row'>
                <th className='block__table-head'></th>
                <th className='block__table-head'>雌火竜の鱗</th>
                <th className='block__table-head'>雌火竜の甲殻</th>
                <th className='block__table-head'>雌火竜の翼膜</th>
                <th className='block__table-head'>雌火竜の棘</th>
                <th className='block__table-head'>雌火竜の良質な鱗</th>
                <th className='block__table-head'>雌火竜の逆鱗</th>
                <th className='block__table-head'>竜玉のかけら</th>
                {/* Add other column headers as needed */}
              </tr>
            </thead>
            <tbody className='block__tbody'>
              <tr className='block__table-row'>
                <td className='block__table-data'>★1</td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
              </tr>
              <tr className='block__table-row'>
              <td className='block__table-data'>★2</td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
              </tr>
              <tr className='block__table-row'>
              <td className='block__table-data'>★3</td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
                <td className='empty__table-data'></td>
              </tr>
              {specificData.map((row, rowIndex) => (
              <tr key={rowIndex} className='block__table-row'>
                {row.map((value, columnIndex) => (
                  <td key={columnIndex} className='block__table-data'>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <div className='monster__table__info'>
             
            <span> 参照：<a href="https://docs.google.com/spreadsheets/u/1/d/e/2PACX-1vT7_b1bELu2eUg9mL_DSDwbWcJAp51YqMgkl70C3nBlbSRR8gNI08bADeGsU-vUwCiIJxKS6GTcJYYu/pubhtml#">
              MHNow Material Drop Rate</a>
            </span>
          </div>
        </div>

      </div>
    );
  };
  
  export default RathianTable;
  