import { Decolation } from "./Components/Decolation";
import { Footer } from "./Components/Footer";
import { Header } from "./Components/Header";
import { Hero } from "./Components/Hero";
import { Main } from "./Components/Main";

const Mainpage = () => {
    return (
        <div>
            <Header />
            <Hero />
            <Decolation />
            <Main />
            <Footer />
        </div>
    );
};

export default Mainpage