import React, { useState, useEffect } from 'react';
import { Decolation } from "../Components/Decolation";
import Radobaanimage from "../images/Radobaan.png";
import IconImage from "../assets/images/icon_tittle.png";
import "../assets/css/reset.css";
import "../assets/css/monstertable.css";
import "../assets/css/monsterpage_base.css";

interface RadobaanData {
  data: {
    Radobaan: string[][];
  };
}

const RadobaanTable: React.FC = () => {
  const [data, setData] = useState<RadobaanData | null>(null);

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/radobaandata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((jsonData) => {
      console.log('Fetched Data:', jsonData);
      // データを文字列に変換する
      const stringData: RadobaanData = {
        data: {
          Radobaan: jsonData.data.Radobaan.map((row: any[]) =>
            row.map((cell: any) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("データの取得中にエラーが発生しました:", error));
  }, []);

  if (!data || !data.data || !data.data.Radobaan) {
    return <div className='loading'><span>Loading...</span></div>;
  }


  const specificData = data.data.Radobaan
    // インデックスの調整
    .slice(2, 11)
    .map((item) => [item[1], item[6], item[9], item[12], item[15], item[18], item[21], item[24]]);

  return (
    <div className='monsterpage'>

      <div className='monster__name'>
        <img src={IconImage} alt="" className="NameIcon" />
        <h3>ラドバルキン</h3>
      </div>


      <div className='monster__topImage'>
        <img src={Radobaanimage} alt="" className="RadobaanImage" />
      </div>

      <div className='monster__table'>
        <div className='monsterpage__title'>
          <h4><span>基本報酬</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <table border={1} className='block__table'>
          <thead className='block__thead'>
            <tr className='block__table-row'>
              <th className='block__table-head'></th>
              <th className='block__table-head'>レア1</th>
              <th className='block__table-head'>レア1</th>
              <th className='block__table-head'>レア2</th>
              <th className='block__table-head'>レア3</th>
              <th className='block__table-head'>レア4</th>
              <th className='block__table-head'>レア5</th>
              <th className='block__table-head'>レア6</th>
            </tr>
            <tr className='block__table-row'>
              <th className='block__table-head'></th>
              <th className='block__table-head'>骨鎚竜の鱗</th>
              <th className='block__table-head'>骨鎚竜の甲殻</th>
              <th className='block__table-head'>骨鎚竜の尻尾</th>
              <th className='block__table-head'>骨鎚竜の黒油殻</th>
              <th className='block__table-head'>骨鎚竜の良質な鱗</th>
              <th className='block__table-head'>骨鎚竜の骨髄</th>
              <th className='block__table-head'>竜玉のかけら</th>
              {/* Add other column headers as needed */}
            </tr>
          </thead>
          <tbody className='block__tbody'>
            <tr className='block__table-row'>
              <td className='block__table-data'>★1</td>
              <td className='empty__table-data'></td>
              <td className='empty__table-data'></td>
              <td className='empty__table-data'></td>
              <td className='empty__table-data'></td>
              <td className='empty__table-data'></td>
              <td className='empty__table-data'></td>
              <td className='empty__table-data'></td>
            </tr>
            {specificData.map((row, rowIndex) => (
              <tr key={rowIndex} className='block__table-row'>
                {row.map((value, columnIndex) => (
                  <td key={columnIndex} className='block__table-data'>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <div className='monster__table__info'>
           
          <span> 参照：<a href="https://docs.google.com/spreadsheets/u/1/d/e/2PACX-1vT7_b1bELu2eUg9mL_DSDwbWcJAp51YqMgkl70C3nBlbSRR8gNI08bADeGsU-vUwCiIJxKS6GTcJYYu/pubhtml#">
            MHNow Material Drop Rate</a>
          </span>
        </div>
      </div>

    </div>
  );
};

export default RadobaanTable;
