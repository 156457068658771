import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleLeftwing } from "../Components/ItemTitleLeftwing";
import { ItemTitleRightwing } from "../Components/ItemTitleRightwing";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import Pukeiimage from "../images/Pukei.png";
interface PukeiData {
  data: {
    Pukei: string[][];
  };
}

const Pukei: React.FC = () => {
  const [data, setData] = useState<PukeiData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/pukeidata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: PukeiData) => {
      // 文字列に変換する
      const stringData: PukeiData = {
        data: {
          Pukei: responseData.data.Pukei.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level1") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PukeiHeadlv1 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[17][12])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv1 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[32][12])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv1 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[47][12])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv1 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[62][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[62][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[62][12])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv1 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[77][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[77][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[77][12])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level2") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PukeiHeadlv2 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[18][15])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv2 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[33][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[33][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[33][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[33][15])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv2 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[48][15])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv2 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[63][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[63][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[63][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[63][15])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv2 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[78][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[78][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[78][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[78][15])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level3") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PukeiHeadlv3 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[19][15])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv3 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[34][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[34][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[34][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[34][15])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv3 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[49][15])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv3 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[64][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[64][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[64][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[64][15])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv3 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[79][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[79][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[79][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[79][15])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PukeiHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[20][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv4 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[35][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv4 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[50][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv4 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[65][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[65][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[65][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[65][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[65][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv4 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[80][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[80][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[80][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[80][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[80][18])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PukeiHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[21][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[21][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[21][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[21][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[21][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[36][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[51][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[66][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[66][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[66][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[66][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[66][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[81][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[81][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[81][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[81][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[81][18])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PukeiHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[22][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[22][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[22][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[22][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[22][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[22][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[37][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[52][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[52][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[52][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[52][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[52][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[52][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[67][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[67][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[67][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[67][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[67][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[67][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[82][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[82][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[82][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[82][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[82][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[82][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PukeiHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[23][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[23][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[23][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[23][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[23][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[23][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[38][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[53][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[53][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[53][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[53][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[53][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[53][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[68][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[68][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[68][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[68][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[68][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[68][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[83][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[83][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[83][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[83][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[83][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[83][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PukeiHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[24][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[24][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[24][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[39][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[54][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[54][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[54][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[54][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[54][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[54][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[54][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[69][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[69][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[69][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[69][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[69][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[69][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[69][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[84][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[84][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[84][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[84][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[84][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[84][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[84][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>
        
        < ItemTitleHead />
        <div className="PukeiHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[25][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[25][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[40][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[55][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[55][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[55][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[55][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[55][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[55][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[55][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[70][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[70][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[70][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[70][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[70][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[70][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[70][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[85][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[85][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[85][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[85][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[85][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[85][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[85][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="PukeiHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[26][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[26][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="PukeiBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[41][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[41][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[41][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[41][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[41][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[41][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[41][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="PukeiLeftWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[56][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[56][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[56][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[56][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[56][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[56][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[56][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="PukeiRightWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[71][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[71][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[71][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[71][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[71][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[71][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[71][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="PukeiTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>毒妖鳥の鱗</a>
            <span>{String(data?.data.Pukei[86][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の甲殻</a>
            <span>{String(data?.data.Pukei[86][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の尻尾</a>
            <span>{String(data?.data.Pukei[86][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の喉袋</a>
            <span>{String(data?.data.Pukei[86][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の羽根</a>
            <span>{String(data?.data.Pukei[86][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>毒妖鳥の良質な鱗</a>
            <span>{String(data?.data.Pukei[86][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Pukei[86][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level1">★1</option>
            <option value="level2">★2</option>
            <option value="level3">★3</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Pukeiimage} alt="" className="PukeiImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Pukei;
