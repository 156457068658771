import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import { ItemTitleLefthorn } from "../Components/ItemTitleLefthorn";
import { ItemTitleRighthorn } from "../Components/ItemTitleRighthorn";
import BlackDiablosimage from "../images/BlackDiablos.png";
interface BlackDiablosData {
  data: {
    BlackDiablos: string[][];
  };
}

const BlackDiablos: React.FC = () => {
  const [data, setData] = useState<BlackDiablosData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/blackdiablosdata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: BlackDiablosData) => {
      // 文字列に変換する
      const stringData: BlackDiablosData = {
        data: {
          BlackDiablos: responseData.data.BlackDiablos.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level5") {
    content = (
      <div className="BlackDiablos">

        < ItemTitleLefthorn />
        <div className="BlackDiablosLeftHornlv5 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[13][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[13][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[13][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[13][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[13][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BlackDiablosRightHornlv5 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[24][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="BlackDiablosBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[35][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BlackDiablosTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[46][18])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div className="BlackDiablos">

        < ItemTitleLefthorn />
        <div className="BlackDiablosLeftHornlv6 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[14][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[14][21])}</span>
          </div>          
        </div>
        
        < ItemTitleRighthorn />
        <div className="BlackDiablosRightHornlv6 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[25][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="BlackDiablosBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[36][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BlackDiablosTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[47][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[47][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div className="BlackDiablos">

        < ItemTitleLefthorn />
        <div className="BlackDiablosLeftHornlv7 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[15][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[15][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BlackDiablosRightHornlv7 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[26][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="BlackDiablosBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[37][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BlackDiablosTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[48][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div className="BlackDiablos">

        < ItemTitleLefthorn />
        <div className="BlackDiablosLeftHornlv8 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[16][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[16][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BlackDiablosRightHornlv8 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[27][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[27][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[27][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="BlackDiablosBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BlackDiablosTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[49][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[49][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div className="BlackDiablos">

        < ItemTitleLefthorn />
        <div className="BlackDiablosLeftHornlv9 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[17][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[17][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BlackDiablosRightHornlv9 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[28][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[28][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="BlackDiablosBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[39][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BlackDiablosTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[50][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div className="BlackDiablos">

        < ItemTitleLefthorn />
        <div className="BlackDiablosLeftHornlv10 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="BlackDiablosRightHornlv10 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[29][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[29][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="BlackDiablosBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[40][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="BlackDiablosTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>黒角竜の甲殻</a>
            <span>{String(data?.data.BlackDiablos[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の牙</a>
            <span>{String(data?.data.BlackDiablos[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の尾甲</a>
            <span>{String(data?.data.BlackDiablos[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の背甲</a>
            <span>{String(data?.data.BlackDiablos[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の良質な甲殻</a>
            <span>{String(data?.data.BlackDiablos[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>黒角竜の骨髄</a>
            <span>{String(data?.data.BlackDiablos[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.BlackDiablos[51][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={BlackDiablosimage} alt="" className="BlackDiablosImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default BlackDiablos;
