import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import LegianaTable from "../MonsterTableComponents/LegianaTable";
import Legiana from "../MonsterbreakComponents/Legiana";

const Legianapage = () => {
    return (
        <div>
            <Header />
            <LegianaTable />
            <Legiana />
            <Footer />
        </div>
    );
};

export default Legianapage