import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import { ItemTitleLefthorn } from "../Components/ItemTitleLefthorn";
import { ItemTitleRighthorn } from "../Components/ItemTitleRighthorn";
import Diablosimage from "../images/Diablos.png";
interface DiablosData {
  data: {
    Diablos: string[][];
  };
}

const Diablos: React.FC = () => {
  const [data, setData] = useState<DiablosData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/diablosdata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: DiablosData) => {
      // 文字列に変換する
      const stringData: DiablosData = {
        data: {
          Diablos: responseData.data.Diablos.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleLefthorn />
        <div className="DiablosLeftHornlv5 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[13][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[13][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[13][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[13][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[13][18])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="DiablosRightHornlv5 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[24][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="DiablosBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[35][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DiablosTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[46][18])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleLefthorn />
        <div className="DiablosLeftHornlv6 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[14][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[14][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="DiablosRightHornlv6 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[25][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="DiablosBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[36][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DiablosTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[47][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[47][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleLefthorn />
        <div className="DiablosLeftHornlv7 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[15][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[15][21])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="DiablosRightHornlv7 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[26][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="DiablosBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[37][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DiablosTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[48][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleLefthorn />
        <div className="DiablosLeftHornlv8 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[16][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[16][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="DiablosRightHornlv8 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[27][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[27][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[27][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="DiablosBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DiablosTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[49][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[49][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleLefthorn />
        <div className="DiablosLeftHornlv9 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[17][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[17][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="DiablosRightHornlv9 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[28][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[28][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="DiablosBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[39][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DiablosTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[50][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleLefthorn />
        <div className="DiablosLeftHornlv10 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleRighthorn />
        <div className="DiablosRightHornlv10 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[29][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[29][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="DiablosBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[40][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="DiablosTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>角竜の甲殻</a>
            <span>{String(data?.data.Diablos[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の牙</a>
            <span>{String(data?.data.Diablos[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の尾甲</a>
            <span>{String(data?.data.Diablos[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の背甲</a>
            <span>{String(data?.data.Diablos[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の良質な甲殻</a>
            <span>{String(data?.data.Diablos[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>角竜の骨髄</a>
            <span>{String(data?.data.Diablos[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Diablos[51][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Diablosimage} alt="" className="DiablosImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Diablos;
