import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import AzureRathalosTable from "../MonsterTableComponents/AzureRathalosTable";
import AzureRathalos from "../MonsterbreakComponents/AzureRathalos";

const AzureRathalospage = () => {
    return (
        <div>
            <Header />
            <AzureRathalosTable />
            <AzureRathalos />
            <Footer />
        </div>
    );
};

export default AzureRathalospage