import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import DosjagrasTable from "../MonsterTableComponents/DosjagrasTable";
import Dosjagras from "../MonsterbreakComponents/Dosjagras";

const Dosjagraspage = () => {
    return (
        <div>
            <Header />
            <DosjagrasTable />
            <Dosjagras />
            <Footer />
        </div>
    );
};

export default Dosjagraspage