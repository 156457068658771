import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleForelegs } from "../Components/ItemTitleForelegs";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import Tobikadachiimage from "../images/Tobikadachi.png";
interface TobikadachiData {
  data: {
    Tobikadachi: string[][];
  };
}

const Tobikadachi: React.FC = () => {
  const [data, setData] = useState<TobikadachiData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/tobikadachidata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: TobikadachiData) => {
      // 文字列に変換する
      const stringData: TobikadachiData = {
        data: {
          Tobikadachi: responseData.data.Tobikadachi.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level2") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TobikadachiHeadlv2 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[16][15])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="TobikadachiBacklv2 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[30][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[30][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[30][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[30][15])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="TobikadachiForelegslv2 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[44][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[44][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[44][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[44][15])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="TobikadachiTaillv2 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[58][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[58][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[58][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[58][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level3") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TobikadachiHeadlv3 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[17][15])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="TobikadachiBacklv3 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[31][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[31][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[31][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[31][15])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="TobikadachiForelegslv3 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[45][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[45][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[45][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[45][15])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="TobikadachiTaillv3 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[59][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[59][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[59][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[59][15])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TobikadachiHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[18][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="TobikadachiBacklv4 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[32][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[32][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[32][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[32][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[32][18])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="TobikadachiForelegslv4 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[46][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="TobikadachiTaillv4 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[60][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[60][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[60][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[60][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[60][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TobikadachiHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[19][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[19][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="TobikadachiBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[33][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[33][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[33][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[33][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[33][18])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="TobikadachiForelegslv5 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[47][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="TobikadachiTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[61][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[61][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[61][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[61][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[61][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TobikadachiHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[20][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[20][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="TobikadachiBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[34][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[34][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[34][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[34][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[34][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[34][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="TobikadachiForelegslv6 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[48][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="TobikadachiTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[62][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[62][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[62][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[62][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[62][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[62][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TobikadachiHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[21][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[21][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[21][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[21][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[21][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[21][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="TobikadachiBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[35][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[35][21])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="TobikadachiForelegslv7 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[49][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="TobikadachiTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[63][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[63][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[63][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[63][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[63][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[63][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TobikadachiHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[22][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[22][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[22][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[22][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[22][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[22][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[22][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="TobikadachiBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[36][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[36][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="TobikadachiForelegslv8 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[50][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="TobikadachiTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[64][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[64][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[64][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[64][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[64][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[64][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[64][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TobikadachiHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[23][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[23][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[23][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[23][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[23][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[23][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[23][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="TobikadachiBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[37][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[37][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="TobikadachiForelegslv9 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[51][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="TobikadachiTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[65][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[65][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[65][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[65][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[65][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[65][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[65][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="TobikadachiHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[24][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[24][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[24][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="TobikadachiBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleForelegs />
        <div className="TobikadachiForelegslv10 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[52][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[52][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[52][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[52][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[52][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[52][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[52][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="TobikadachiTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>飛雷竜の鱗</a>
            <span>{String(data?.data.Tobikadachi[66][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の爪</a>
            <span>{String(data?.data.Tobikadachi[66][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の毛皮</a>
            <span>{String(data?.data.Tobikadachi[66][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の皮膜</a>
            <span>{String(data?.data.Tobikadachi[66][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の良質な鱗</a>
            <span>{String(data?.data.Tobikadachi[66][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>飛雷竜の電極針</a>
            <span>{String(data?.data.Tobikadachi[66][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Tobikadachi[66][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level2">★2</option>
            <option value="level3">★3</option>
            <option value="level4">★4</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Tobikadachiimage} alt="" className="TobikadachiImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Tobikadachi;
