import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import DeviljhoTable from "../MonsterTableComponents/DeviljhoTable";
import Deviljho from "../MonsterbreakComponents/Deviljho";

const Deviljhopage = () => {
    return (
        <div>
            <Header />
            <DeviljhoTable />
            <Deviljho />
            <Footer />
        </div>
    );
};

export default Deviljhopage