import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import RadobaanTable from "../MonsterTableComponents/RadobaanTable";
import Radobaan from "../MonsterbreakComponents/Radobaan";

const Radobaanpage = () => {
    return (
        <div>
            <Header />
            <RadobaanTable />
            <Radobaan />
            <Footer />
        </div>
    );
};

export default Radobaanpage