import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import ZinogreTable from "../MonsterTableComponents/ZinogreTable";
import Zinogre from "../MonsterbreakComponents/Zinogre";

const Zinogrepage = () => {
    return (
        <div>
            <Header />
            <ZinogreTable />
            <Zinogre />
            <Footer />
        </div>
    );
};

export default Zinogrepage