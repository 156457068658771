import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import DiablosTable from "../MonsterTableComponents/DiablosTable";
import Diablos from "../MonsterbreakComponents/Diablos";

const Diablospage = () => {
    return (
        <div>
            <Header />
            <DiablosTable />
            <Diablos />
            <Footer />
        </div>
    );
};

export default Diablospage