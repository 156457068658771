import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleLeftwing } from "../Components/ItemTitleLeftwing";
import { ItemTitleRightwing } from "../Components/ItemTitleRightwing";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import { ItemTitleNeckpouch } from "../Components/ItemTitleNeckpouch";
import Paolumuimage from "../images/Paolumu.png";
interface PaolumuData {
  data: {
    Paolumu: string[][];
  };
}

const Paolumu: React.FC = () => {
  const [data, setData] = useState<PaolumuData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/paolumudata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: PaolumuData) => {
      // 文字列に変換する
      const stringData: PaolumuData = {
        data: {
			Paolumu: responseData.data.Paolumu.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level3") {
    content = (
      <div>

		< ItemTitleNeckpouch />
        <div className="PaolumuNeckPouchlv3 dropRate__box">
			<div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[15][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[15][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[15][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[15][15])}</span>
			</div> 
        </div>
        
		< ItemTitleBack />
        <div className="PaolumuBacklv3 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[28][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[28][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[28][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[28][15])}</span>
			</div>
        </div>
        
		< ItemTitleLeftwing />
        <div className="PaolumuLeftWinglv3 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[41][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[41][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[41][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[41][15])}</span>
			</div>
        </div>
        
		< ItemTitleRightwing />
        <div className="PaolumuRightWinglv3 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[54][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[54][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[54][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[54][15])}</span>
			</div>
        </div>
        
		< ItemTitleTail />
        <div className="PaolumuTaillv3 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[67][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[67][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[67][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[67][15])}</span>
			</div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div>

		< ItemTitleNeckpouch />
        <div className="PaolumuNeckPouchlv4 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[16][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[16][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[16][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[16][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[16][18])}</span>
			</div>
        </div>
        
		< ItemTitleBack />
        <div className="PaolumuBacklv4 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[29][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[29][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[29][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[29][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[29][18])}</span>
			</div>
        </div>
        
		< ItemTitleLeftwing />
        <div className="PaolumuLeftWinglv4 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[42][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[42][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[42][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[42][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[42][18])}</span>
			</div>
        </div>
        
		< ItemTitleRightwing />
        <div className="PaolumuRightWinglv4 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[55][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[55][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[55][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[55][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[55][18])}</span>
			</div>
        </div>

		< ItemTitleTail />
        <div className="PaolumuTaillv4 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[68][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[68][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[68][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[68][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[68][18])}</span>
			</div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

		< ItemTitleNeckpouch />
        <div className="PaolumuNeckPouchlv5 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[17][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[17][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[17][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[17][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[17][18])}</span>
			</div>
        </div>
        
		< ItemTitleBack />
        <div className="PaolumuBacklv5 dropRate__box">
		  <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[30][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[30][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[30][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[30][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[30][18])}</span>
			</div>
        </div>
        
		< ItemTitleLeftwing />
        <div className="PaolumuLeftWinglv5 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[43][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[43][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[43][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[43][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[43][18])}</span>
			</div>
        </div>
        
		< ItemTitleRightwing />
        <div className="PaolumuRightWinglv5 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[56][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[56][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[56][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[56][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[56][18])}</span>
			</div>
        </div>
        
		< ItemTitleTail />
        <div className="PaolumuTaillv5 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[69][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[69][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[69][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[69][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[69][18])}</span>
			</div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div className="Paolumu">

		< ItemTitleNeckpouch />
        <div className="PaolumuNeckPouchlv6 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[18][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[18][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[18][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[18][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[18][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[18][21])}</span>
			</div>
        </div>
        
		< ItemTitleBack />
        <div className="PaolumuBacklv6 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[31][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[31][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[31][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[31][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[31][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[31][21])}</span>
			</div>
        </div>
        
		< ItemTitleLeftwing />
        <div className="PaolumuLeftWinglv6 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[44][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[44][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[44][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[44][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[44][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[44][21])}</span>
			</div>
        </div>
        
		< ItemTitleRightwing />
        <div className="PaolumuRightWinglv6 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[57][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[57][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[57][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[57][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[57][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[57][21])}</span>
			</div>
        </div>
        
		< ItemTitleTail />
        <div className="PaolumuTaillv6 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[70][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[70][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[70][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[70][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[70][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[70][21])}</span>
			</div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div className="Paolumu">

		< ItemTitleNeckpouch />
        <div className="PaolumuNeckPouchlv7 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[19][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[19][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[19][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[19][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[19][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[19][21])}</span>
			</div>
        </div>
        
		< ItemTitleBack />
        <div className="PaolumuBacklv7 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[32][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[32][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[32][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[32][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[32][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[32][21])}</span>
			</div>
        </div>
        
		< ItemTitleLeftwing />
        <div className="PaolumuLeftWinglv7 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[45][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[45][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[45][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[45][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[45][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[45][21])}</span>
			</div>
        </div>
        
		< ItemTitleRightwing />
        <div className="PaolumuRightWinglv7 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[58][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[58][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[58][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[58][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[58][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[58][21])}</span>
			</div>
        </div>
        
		< ItemTitleTail />
        <div className="PaolumuTaillv7 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[71][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[71][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[71][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[71][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[71][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[71][21])}</span>
			</div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div className="Paolumu">

		< ItemTitleNeckpouch />
        <div className="PaolumuNeckPouchlv8 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[20][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[20][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[20][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[20][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[20][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[20][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[20][24])}</span>
			</div>
        </div>
        
		< ItemTitleBack />
        <div className="PaolumuBacklv8 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[33][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[33][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[33][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[33][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[33][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[33][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[33][24])}</span>
			</div>
        </div>
        
		< ItemTitleLeftwing />
        <div className="PaolumuLeftWinglv8 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[46][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[46][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[46][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[46][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[46][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[46][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[46][24])}</span>
			</div>
        </div>
        
		< ItemTitleRightwing />
        <div className="PaolumuRightWinglv8 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[59][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[59][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[59][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[59][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[59][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[59][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[59][24])}</span>
			</div>
        </div>
        
		< ItemTitleTail />
        <div className="PaolumuTaillv8 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[72][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[72][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[72][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[72][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[72][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[72][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[72][24])}</span>
			</div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div className="Paolumu">

		< ItemTitleNeckpouch />
        <div className="PaolumuNeckPouchlv9 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[21][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[21][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[21][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[21][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[21][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[21][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[21][24])}</span>
			</div>
        </div>
        
		< ItemTitleBack />
        <div className="PaolumuBacklv9 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[34][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[34][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[34][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[34][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[34][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[34][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[34][24])}</span>
			</div>
        </div>
        
		< ItemTitleLeftwing />
        <div className="PaolumuLeftWinglv9 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[47][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[47][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[47][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[47][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[47][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[47][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[47][24])}</span>
			</div>
        </div>
        
		< ItemTitleRightwing />
        <div className="PaolumuRightWinglv9 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[60][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[60][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[60][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[60][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[60][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[60][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[60][24])}</span>
			</div>
        </div>
        
		< ItemTitleTail />
        <div className="PaolumuTaillv9 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[73][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[73][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[73][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[73][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[73][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[73][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[73][24])}</span>
			</div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div className="Paolumu">

		< ItemTitleNeckpouch />
        <div className="PaolumuNeckPouchlv10 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[22][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[22][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[22][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[22][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[22][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[22][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[22][24])}</span>
			</div>
        </div>
        
		< ItemTitleBack />
        <div className="PaolumuBacklv10 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[35][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[35][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[35][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[35][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[35][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[35][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[35][24])}</span>
			</div>
        </div>
        
		< ItemTitleLeftwing />
        <div className="PaolumuLeftWinglv10 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[48][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[48][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[48][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[48][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[48][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[48][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[48][24])}</span>
			</div>
        </div>
        
		< ItemTitleRightwing />
        <div className="PaolumuRightWinglv10 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[61][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[61][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[61][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[61][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[61][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[61][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[61][24])}</span>
			</div>
        </div>
        
		< ItemTitleTail />
        <div className="PaolumuTaillv10 dropRate__box">
		    <div className="dropRate__item">
				<a>浮空竜の鱗</a>
				<span>{String(data?.data.Paolumu[74][6])} </span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の毛皮</a>
				<span>{String(data?.data.Paolumu[74][9])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の翼膜</a>
				<span>{String(data?.data.Paolumu[74][12])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の甲殻</a>
				<span>{String(data?.data.Paolumu[74][15])}</span>
			</div>
			<div className="dropRate__item">
				<a>浮空竜の上質な鱗</a>
				<span>{String(data?.data.Paolumu[74][18])}</span>
			</div>
			<div className="dropRate__item">
				<a>ゴム質の良質な甲殻</a>
				<span>{String(data?.data.Paolumu[74][21])}</span>
			</div>
			<div className="dropRate__item">
				<a>竜玉のかけら</a>
				<span>{String(data?.data.Paolumu[74][24])}</span>
			</div>
        </div>
				
      </div>
    );
  }
  

  return (
    <div  className="monsterpage">

		<div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
		<div className="selectButton">
          <span>レベル</span>
            <select value={selectedOption} onChange={handleChange}>
                <option value="">選択してください</option>
                <option value="level3">★3</option>
                <option value="level4">★4</option>
                <option value="level5">★5</option>
                <option value="level6">★6</option>
                <option value="level7">★7</option>
                <option value="level8">★8</option>
                <option value="level9">★9</option>
                <option value="level10">★10</option>
            </select>
        </div>

        <div className="ImageContainer">
            <img src={Paolumuimage} alt="" className="PaolumuImage" />
        </div>

		{content}
      </div>

    </div>
  );
};

export default Paolumu;
