import React,{ useState } from "react";
import { PulldownMenu } from './PulldownMenu';
import "../assets/css/reset.css";
import "../assets/css/header.css";
import { Link, } from "react-router-dom";

export const Header = () => {
    const [ isVisible, setIsVisible] = useState(false)

    return (
        <div className="header">
            <div className="header__inner">
                <div className="header__title">
                    <h1>
                        <Link to ="/">モンハンNow攻略</Link>
                    </h1>
                    {/* <span className="decolation__line"></span>
                    <div className="header__langButton">
                        <a href="#" className="langButton">
                            日本語
                        </a>
                        <a href="#" className="langButton">
                            English
                        </a>
                    </div> */}
                </div>
                <button onClick={() => setIsVisible(!isVisible)}>モンスター一覧</button>

                {isVisible && < PulldownMenu />}
            </div>
        </div>
    );
};
