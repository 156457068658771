import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleTail } from "../Components/ItemTitleTail";
import { ItemTitleLeftwing } from "../Components/ItemTitleLeftwing";
import { ItemTitleRightwing } from "../Components/ItemTitleRightwing";
import { ItemTitleBack } from "../Components/ItemTitleBack";
import Legianaimage from "../images/Legiana.png";
interface LegianaData {
  data: {
    Legiana: string[][];
  };
}

const Legiana: React.FC = () => {
  const [data, setData] = useState<LegianaData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/legianadata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: LegianaData) => {
      // 文字列に変換する
      const stringData: LegianaData = {
        data: {
          Legiana: responseData.data.Legiana.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="LegianaHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[13][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[13][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[13][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[13][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[13][18])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="LegianaLeftWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[24][18])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="LegianaRightWinglv5 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[35][18])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="LegianaTaillv5 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[46][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[46][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[46][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[46][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[46][18])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="LegianaBacklv5 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[57][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[57][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[57][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[57][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[57][18])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="LegianaHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[14][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[14][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[14][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[14][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[14][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[14][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="LegianaLeftWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[25][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="LegianaRightWinglv6 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[36][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[36][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="LegianaTaillv6 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[47][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[47][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[47][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[47][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[47][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[47][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="LegianaBacklv6 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[58][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[58][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[58][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[58][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[58][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[58][21])}</span>
          </div>
        </div>
        
      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="LegianaHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[15][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[15][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[15][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[15][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[15][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[15][21])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="LegianaLeftWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[26][21])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="LegianaRightWinglv7 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[37][21])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="LegianaTaillv7 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[48][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[48][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[48][21])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="LegianaBacklv7 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[59][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[59][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[59][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[59][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[59][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[59][21])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="LegianaHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[16][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[16][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[16][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[16][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[16][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[16][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[16][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="LegianaLeftWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[27][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[27][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[27][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[27][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[27][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[27][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[27][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="LegianaRightWinglv8 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[38][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[38][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="LegianaTaillv8 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[49][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[49][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[49][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[49][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="LegianaBacklv8 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[60][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[60][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[60][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[60][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[60][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[60][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[60][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="LegianaHeadlv9 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[17][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[17][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[17][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[17][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[17][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[17][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[17][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="LegianaLeftWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[28][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[28][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[28][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[28][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[28][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[28][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[28][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="LegianaRightWinglv9 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[39][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="LegianaTaillv9 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[50][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[50][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[50][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="LegianaBacklv9 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[61][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[61][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[61][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[61][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[61][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[61][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[61][24])}</span>
          </div>
        </div>
      </div>
    );
  }
  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="LegianaHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[18][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[18][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[18][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[18][24])}</span>
          </div>
        </div>
        
        < ItemTitleLeftwing />
        <div className="LegianaLeftWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[29][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[29][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[29][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[29][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[29][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[29][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[29][24])}</span>
          </div>
        </div>
        
        < ItemTitleRightwing />
        <div className="LegianaRightWinglv10 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[40][24])}</span>
          </div>
        </div>
        
        < ItemTitleTail />
        <div className="LegianaTaillv10 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[51][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[51][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[51][24])}</span>
          </div>
        </div>
        
        < ItemTitleBack />
        <div className="LegianaBacklv10 dropRate__box">
          <div className="dropRate__item">
            <a>風漂竜の鱗</a>
            <span>{String(data?.data.Legiana[62][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の皮</a>
            <span>{String(data?.data.Legiana[62][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の爪</a>
            <span>{String(data?.data.Legiana[62][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の翼膜</a>
            <span>{String(data?.data.Legiana[62][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の良質な鱗</a>
            <span>{String(data?.data.Legiana[62][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>風漂竜の逆鱗</a>
            <span>{String(data?.data.Legiana[62][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Legiana[62][24])}</span>
          </div>
        </div>
        
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">
        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>
        
        <div className="selectButton">
          <span>レベル</span>
          <select value={selectedOption} onChange={handleChange}>
            <option value="">選択してください</option>
            <option value="level5">★5</option>
            <option value="level6">★6</option>
            <option value="level7">★7</option>
            <option value="level8">★8</option>
            <option value="level9">★9</option>
            <option value="level10">★10</option>
          </select>
        </div>

        <div className="ImageContainer">
          <img src={Legianaimage} alt="" className="LegianaImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Legiana;
