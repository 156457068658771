import React, { useState, useEffect, ChangeEvent } from "react";
import { Decolation } from "../Components/Decolation";
import { ItemTitleHead } from "../Components/ItemTitleHead";
import { ItemTitleForelegs } from "../Components/ItemTitleForelegs";
import { ItemTitleStomach } from "../Components/ItemTitleStomach";
import Dosjagrasimage from "../images/Greatjagras.png";
import "../assets/css/reset.css";
import "../assets/css/monsterpage_base.css";
interface DosjagrasData {
  data: {
    Dosjagras: string[][];
  };
}

const Dosjagras: React.FC = () => {
  const [data, setData] = useState<DosjagrasData | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    fetch("https://4rgh9cdep6.execute-api.ap-northeast-1.amazonaws.com/deploymentstage/dosjagrasdata", {
      method: "GET",
      mode: "cors", // CORS を有効にする
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((responseData: DosjagrasData) => {
      if (!responseData.data || !responseData.data.Dosjagras) {
        throw new Error("Invalid data format");
      }
      // 文字列に変換する
      const stringData: DosjagrasData = {
        data: {
          Dosjagras: responseData.data.Dosjagras.map((row) =>
            row.map((cell) => String(cell))
          ),
        },
      };
      setData(stringData);
    })
    
    .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  let content: JSX.Element = <div className="selectMessage"><span>クエスト難易度を選択</span></div>;

  if (selectedOption === "level1" && data) {
    content = (
      <div>
        < ItemTitleHead />
        <div className="DosjagrasHeadlv1 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[17]?.[6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[17]?.[9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[17]?.[12])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv1 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[32]?.[6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[32]?.[9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[32]?.[12])}</span>
          </div>
        </div>

        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv1 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[47]?.[6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[47]?.[9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[47]?.[12])}</span>
          </div>
        </div>

      </div>
    );
  }

  if (selectedOption === "level2") {
    content = (
      <div>
        
        < ItemTitleHead />
        <div className="DosjagrasHeadlv2 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[18][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[18][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[18][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[18][15])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv2 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[33][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[33][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[33][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[33][15])}</span>
          </div>
        </div>

        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv2 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[48][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[48][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[48][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[48][15])}</span>
          </div>
        </div>

      </div>
    );
  }
  if (selectedOption === "level3") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosjagrasHeadlv3 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[19][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[19][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[19][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[19][15])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv3 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[34][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[34][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[34][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[34][15])}</span>
          </div>
        </div>
        
        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv3 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[49][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[49][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[49][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[49][15])}</span>
          </div>
        </div>

      </div>
    );
  }
  if (selectedOption === "level4") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosjagrasHeadlv4 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[20][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[20][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[20][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[20][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[20][18])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv4 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[35][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[35][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[35][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[35][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[35][18])}</span>
          </div>
        </div>

        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv4 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[50][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[50][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[50][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[50][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[50][18])}</span>
          </div>
        </div>

      </div>
    );
  }
  if (selectedOption === "level5") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosjagrasHeadlv5 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[21][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[21][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[21][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[21][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[21][18])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv5 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[36][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[36][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[36][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[36][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[36][18])}</span>
          </div>
        </div>

        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv5 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[51][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[51][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[51][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[51][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[51][18])}</span>
          </div>
        </div>

      </div>
    );
  }
  if (selectedOption === "level6") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosjagrasHeadlv6 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[22][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[22][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[22][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[22][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[22][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span className="red">{String(data?.data.Dosjagras[22][21])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv6 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[37][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[37][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[37][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[37][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[37][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[37][21])}</span>
          </div>
        </div>

        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv6 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[52][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[52][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[52][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[52][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[52][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[52][21])}</span>
          </div>
        </div>

      </div>
    );
  }
  if (selectedOption === "level7") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosjagrasHeadlv7 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[23][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[23][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[23][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[23][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[23][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[23][21])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv7 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[38][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[38][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[38][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[38][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[38][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[38][21])}</span>
          </div>
        </div>

        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv7 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[53][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[53][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[53][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[53][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[53][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[53][21])}</span>
          </div>
        </div>

      </div>
    );
  }
  if (selectedOption === "level8") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosjagrasHeadlv8 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[24][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[24][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[24][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[24][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[24][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[24][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosjagras[24][24])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv8 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[39][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[39][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[39][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[39][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[39][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[39][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosjagras[39][24])}</span>
          </div>
        </div>

        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv8 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[54][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[54][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[54][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[54][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[54][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[54][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosjagras[54][24])}</span>
          </div>
        </div>

      </div>
    );
  }
  if (selectedOption === "level9") {
    content = (
      <div>
        
        < ItemTitleHead />
        <div className="DosjagrasHeadlv9 dropRate__box dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[25][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[25][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[25][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[25][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[25][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[25][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span className="red">{String(data?.data.Dosjagras[25][24])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv9 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[40][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[40][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[40][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[40][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[40][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[40][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosjagras[40][24])}</span>
          </div>
        </div>

        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv9 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[55][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[55][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[55][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[55][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[55][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[55][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosjagras[55][24])}</span>
          </div>
        </div>

      </div>
    );
  }

  if (selectedOption === "level10") {
    content = (
      <div>

        < ItemTitleHead />
        <div className="DosjagrasHeadlv10 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[26][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[26][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[26][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[26][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[26][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[26][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosjagras[26][24])}</span>
          </div>
        </div>

        < ItemTitleForelegs />
        <div className="DosjagrasForelegslv10 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[41][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[41][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[41][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[41][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[41][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[41][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosjagras[41][24])}</span>
          </div>

        </div>

        < ItemTitleStomach />
        <div className="Dosjagrasstomachlv10 dropRate__box">
          <div className="dropRate__item">
            <a>賊竜の鱗</a>
            <span>{String(data?.data.Dosjagras[56][6])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の皮</a>
            <span>{String(data?.data.Dosjagras[56][9])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の爪</a>
            <span>{String(data?.data.Dosjagras[56][12])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜のたてがみ</a>
            <span>{String(data?.data.Dosjagras[56][15])}</span>
          </div>
          <div className="dropRate__item">
            <a>賊竜の良質な鱗</a>
            <span>{String(data?.data.Dosjagras[56][18])}</span>
          </div>
          <div className="dropRate__item">
            <a>牙竜玉</a>
            <span>{String(data?.data.Dosjagras[56][21])}</span>
          </div>
          <div className="dropRate__item">
            <a>竜玉のかけら</a>
            <span>{String(data?.data.Dosjagras[56][24])}</span>
          </div>
          
        </div>
      </div>
    );
  }

  return (
    <div className="monsterpage">

      <div className="dropRate">

        <div className="monsterpage__title">
          <h4><span>破壊部位別</span><span>ドロップ率</span></h4>
          <span>< Decolation /></span>
        </div>

        <div className="selectButton">
            <span>レベル</span>
            <select value={selectedOption} onChange={handleChange}>
                <option value="">選択してください</option>
                <option value="level1">★1</option>
                <option value="level2">★2</option>
                <option value="level3">★3</option>
                <option value="level4">★4</option>
                <option value="level5">★5</option>
                <option value="level6">★6</option>
                <option value="level7">★7</option>
                <option value="level8">★8</option>
                <option value="level9">★9</option>
                <option value="level10">★10</option>
            </select>
        </div>
        
        <div className="ImageContainer">
          <img src={Dosjagrasimage} alt="" className="DosjagrasImage" />
        </div>

        {content}
      </div>

    </div>
  );
};

export default Dosjagras;
