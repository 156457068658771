import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import AnjanathTable from "../MonsterTableComponents/AnjanathTable";
import Anjanath from "../MonsterbreakComponents/Anjanath";

const Anjanathpage = () => {
    return (
        <div>
            <Header />
            <AnjanathTable />
            <Anjanath />
            <Footer />
        </div>
    );
};

export default Anjanathpage