import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import BariothTable from "../MonsterTableComponents/BariothTable";
import Barioth from "../MonsterbreakComponents/Barioth";

const Bariothpage = () => {
    return (
        <div>
            <Header />
            <BariothTable />
            <Barioth />
            <Footer />
        </div>
    );
};

export default Bariothpage