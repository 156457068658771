import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import BarrothTable from "../MonsterTableComponents/BarrothTable";
import Barroth from "../MonsterbreakComponents/Barroth";

const Barrothpage = () => {
    return (
        <div>
            <Header />
            <BarrothTable />
            <Barroth />
            <Footer />
        </div>
    );
};

export default Barrothpage