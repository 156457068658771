import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import DosGirrosTable from "../MonsterTableComponents/DosgirrosTable";
import DosGirros from "../MonsterbreakComponents/DosGirros";

const Pukeipage = () => {
    return (
        <div>
            <Header />
            <DosGirrosTable />
            <DosGirros />
            <Footer />
        </div>
    );
};

export default Pukeipage