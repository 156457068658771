import React from 'react'
import { DecolationShort } from "../Components/DecolationShort";

export const ItemTitleLefthindleg = () => {
  return (
    <div className="dropRate__title">
        <h5>左後脚</h5>
        <span>< DecolationShort /></span>
    </div>
  )
}
