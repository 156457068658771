import React from 'react'
import "../assets/css/reset.css";
import "../assets/css/main.css";

import iconTittle from "../assets/images/icon_tittle.png";
import GreatJagras from "../assets/images/monster_icon_GreatJagras.png";
import KuluYaku from "../assets/images/monster_icon_KuluYaKu.png";
import PukeiPukei from "../assets/images/monster_icon_PukeiPukei.png";
import Barroth from "../assets/images/monster_icon_Barroth.png";
import GreatGirros from "../assets/images/monster_icon_GreatGirros.png";
import TobiKadachi from "../assets/images/monster_icon_TobiKadachi.png";
import Paolumu from "../assets/images/monster_icon_Paolumu.png";
import Jyuratodus from "../assets/images/monster_icon_Jyuratodus.png";
import Anjanath from "../assets/images/monster_icon_Anjanath.png";
import Rathian from "../assets/images/monster_icon_Rathian.png";
import Legiana from "../assets/images/monster_icon_Legiana.png";
import Diablos from "../assets/images/monster_icon_Diablos.png";
import Rathalos from "../assets/images/monster_icon_Rathalos.png";
import AzureRathalos from "../assets/images/monster_icon_AzureRathalos.png";
import BlackDiablos from "../assets/images/monster_icon_BlackDiablos.png";
import PinkRathian from "../assets/images/monster_icon_PinkRathian.png";
import Radobaan from "../assets/images/monster_icon_Radobaan.png";
import Banbaro from "../assets/images/monster_icon_Banbaro.png";
import Barioth from "../assets/images/monster_icon_Barioth.png";
import Zinogre from "../assets/images/monster_icon_Zinogre.png";
import Coralpukei from "../assets/images/monster_icon_coral-pukei-pukei.png";
import Deviljho from "../assets/images/monster_icon_deviljho.png";
import tzitziyaku from "../assets/images/monster_icon_tzitzi.png";
import Odogaron from "../assets/images/monster_icon_odogaron.png";

export const Main = () => {
  return (
    <div className='main'>
        <div className='main__inner'>
            <div className='main__inner__title'>
                <img className='iconTitle' src={ iconTittle }/>
                <h2>各モンスタードロップ率</h2>
            </div>
            <ul>
                <li>
                    <a href="/dosjagrasdata">
                        <img src={ GreatJagras }/>
                        <span>ドスジャグラス</span>
                    </a>
                </li>
                <li>
                    <a href="/kuluYakudata">
                        <img src={ KuluYaku }/>
                        <span>クルルヤック</span>
                    </a>
                </li>
                <li>
                    <a href="/pukeidata">
                        <img src={ PukeiPukei }/>
                        <span>プケプケ</span>
                    </a>
                </li>
                <li>
                    <a href="/Coralpukeidata">
                        <img src={ Coralpukei }/>
                        <span>プケプケ亜種</span>
                    </a>
                </li>
                <li>
                    <a href="/barrothdata">
                        <img src={ Barroth }/>
                        <span>ボルボロス</span>
                    </a>
                </li>
                <li>
                    <a href="/dosgirrosdata">
                        <img src={ GreatGirros }/>
                        <span>ドスギルオス</span>
                    </a>
                </li>
                <li>
                    <a href="/tobikadachidata">
                        <img src={ TobiKadachi }/>
                        <span>トビカガチ</span>
                    </a>

                </li>
                <li>
                    <a href="/paolumudata">
                        <img src={ Paolumu }/>
                        <span>パオウルムー</span>
                    </a>
                </li>
                <li>
                    <a href="/jyuratodusdata">
                        <img src={ Jyuratodus }/>
                        <span>ジュラトドス</span>
                    </a>
                </li>
                <li>
                    <a href="/anjanathdata">
                        <img src={ Anjanath }/>
                        <span>アンジャナフ</span>
                    </a>
                </li>
                <li>
                    <a href="/rathiandata">
                        <img src={ Rathian }/>
                        <span>リオレイア</span>
                    </a>
                </li>
                <li>
                    <a href="/pinkrathiandata">
                        <img src={ PinkRathian }/>
                        <span>リオレイア</span><span>亜種</span>
                    </a>
                </li>
                <li>
                    <a href="/legianadata">
                        <img src={ Legiana }/>
                        <span>レイギエナ</span>
                    </a>
                </li>
                <li>
                    <a href="/diablosdata">
                        <img src={ Diablos }/>
                        <span>ディアブロス</span>
                    </a>
                </li>
                <li>
                    <a href="/blackdiablosdata">
                        <img src={ BlackDiablos }/>
                        <span>ディアブロス</span><span>亜種</span>
                    </a>
                </li>
                <li>
                    <a href="/rathalosdata">
                        <img src={ Rathalos }/>
                        <span>リオレウス</span>
                    </a>
                </li>
                <li>
                    <a href="/azurerathalosdata">
                        <img src={ AzureRathalos }/>
                        <span>リオレウス</span><span>亜種</span>
                    </a>
                </li>
                <li>
                    <a href="/radobaandata">
                        <img src={ Radobaan }/>
                        <span>ラドバルキン</span>
                    </a>
                </li>
                <li>
                    <a href="/banbarodata">
                        <img src={ Banbaro }/>
                        <span>バフバロ</span>
                    </a>
                </li>
                <li>
                    <a href="/bariothdata">
                        <img src={ Barioth }/>
                        <span>ベリオロス</span>
                    </a>
                </li>
                <li>
                    <a href="/zinogredata">
                        <img src={ Zinogre }/>
                        <span>ジンオウガ</span>
                    </a>
                </li>
                <li>
                    <a href="/odogarondata">
                        <img src={Odogaron}/>
                        <span>オドガロン</span>
                    </a>
                </li>
                <li>
                    <a href="/tzitziyakudata">
                        <img src={tzitziyaku}/>
                        <span>ツィツィヤック</span>
                    </a>
                </li>
                <li>
                    <a href="/deviljhodata">
                        <img src={Deviljho}/>
                        <span>イビルジョー</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
  )
}
