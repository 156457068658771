// App.jsx
import React from "react";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";

import Dosjagraspage from "./Monsterpage/Dosjagraspage"
import KuluYakupage from "./Monsterpage/Kululuyakupage";
import AzureRathalospage from "./Monsterpage/AzureRathalospage";
import PinkRathianpage from "./Monsterpage/PinkRathianpage";
import BlackDiablospage from "./Monsterpage/BlackDiablospage";
import Pukeipage from "./Monsterpage/Pukeipage";
import Barrothpage from "./Monsterpage/Barrothpage";
import DosGirrospage from "./Monsterpage/DosGirrospage";
import Tobikadachipage from "./Monsterpage/Tobikadachipage";
import Paolumupage from "./Monsterpage/Paolumupage";
import Jyuratoduspage from "./Monsterpage/Jyuratoduspage";
import Rathianpage from "./Monsterpage/Rathianpage";
import Anjanathpage from "./Monsterpage/Anjanathpage";
import Legianapage from "./Monsterpage/Legianapage";
import Rathalospage from "./Monsterpage/Rathalospage";
import Diablospage from "./Monsterpage/Diablospage";
import Radobaanpage from "./Monsterpage/Radobaanpage";
import Banbaropage from "./Monsterpage/Banbaropage";
import Bariothpage from "./Monsterpage/Bariothpage";
import Zinogrepage from "./Monsterpage/Zinogrepage";
import Coralpukeipage from "./Monsterpage/Coralpukeipage";
import Odogaronpage from "./Monsterpage/Odogaronpage";
import Tzitziyakupage from "./Monsterpage/Tzitziyakupage";
import Mainpage from "./Mainpage";
import PrivacyPage from "./Components/PrivacyPage"
import Deviljhopage from "./Monsterpage/Deviljhopage";

const App = () => {
  return (
    <Router>
        <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/dosjagrasdata" element={<Dosjagraspage />} />
        <Route path="/kuluYakudata" element={<KuluYakupage />} />
        <Route path="/pukeidata" element={<Pukeipage />} />
        <Route path="/barrothdata" element={<Barrothpage />} />
        <Route path="/dosgirrosdata" element={<DosGirrospage />} />
        <Route path="/tobikadachidata" element={<Tobikadachipage />} />
        <Route path="/paolumudata" element={<Paolumupage />} />
        <Route path="/jyuratodusdata" element={<Jyuratoduspage />} />
        <Route path="/anjanathdata" element={<Anjanathpage />} />
        <Route path="/rathiandata" element={<Rathianpage/>} />
        <Route path="/legianadata" element={<Legianapage />} />
        <Route path="/rathalosdata" element={<Rathalospage />} />
        <Route path="/diablosdata" element={<Diablospage />} />
        <Route path="/radobaandata" element={<Radobaanpage />} />
        <Route path="/banbarodata" element={<Banbaropage />} />
        <Route path="/bariothdata" element={<Bariothpage />} />
        <Route path="/zinogredata" element={<Zinogrepage />} />
        <Route path="/azurerathalosdata" element={<AzureRathalospage />} />
        <Route path="/pinkrathiandata" element={<PinkRathianpage />} />
        <Route path="/blackdiablosdata" element={<BlackDiablospage />} />
        <Route path="/coralpukeidata" element={<Coralpukeipage />} />
        <Route path="/odogarondata" element={<Odogaronpage />} />
        <Route path="/tzitziyakudata" element={<Tzitziyakupage />} />
        <Route path="/deviljhodata" element={<Deviljhopage />} />
        <Route path="/privacy" element={<PrivacyPage/>} />
      </Routes>
    </Router>
  );
};

export default App;
